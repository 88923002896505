import { privateRequest } from "config/axios.config";
import { PaginationParamsTypes } from "types/pagination.types";
import { ITraningBannerCreate } from "types/training-banner";

/* List of resources */
export const index = async (reqParams: PaginationParamsTypes) => {
  return await privateRequest.get(`api/admin/training`, {
    params: { ...reqParams },
  });
};

/* Store resource */
export const store = async (data: ITraningBannerCreate) => {
  return await privateRequest.post(`api/admin/training`, data);
};

/* Destroy specific resource */
export const destroy = async (id: string) => {
  return await privateRequest.delete(`api/admin/training/${id}`);
};
