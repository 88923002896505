import jwt_decode from "jwt-decode";
import { Toastify } from "components/toastify";
import { jwtTokenType } from "types/jwt";

/* Set token */
export const setToken = async (token: string): Promise<boolean> => {
  localStorage.setItem("token", token);
  return true;
};

/* Get token */
export const getToken = () => {
  return localStorage.getItem("token");
};

/* Remove token */
export const removeToken = () => {
  localStorage.removeItem("token");
  return true;
};

/* Phone number valid check */
export const isValidPhone = () => {
  const regex = /^(?:\+88|88)?(01[3-9]\d{8})$/i;
  return regex;
};

/* E-mail valid check */
export const isValidEmail = () => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return regex;
};

/* URL valid check */
export const isValidURL = () => {
  const regex = /^(ftp|http|https):\/\/[^ "]+$/i;
  return regex;
};

/* Active path */
export const isActivePath = (current_url_path: string, route_path: string) => {
  const current_url_path_array = current_url_path.split("/");
  const isAvailablePath = current_url_path_array.find((x) => x === route_path);

  if (isAvailablePath) {
    return true;
  }
  return false;
};

/* Global network error handeller */
export const networkErrorHandeller = (error: any) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.errors
  ) {
    const obj = error.response.data.errors;
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key].length) {
          if (obj[key][0] === "Token is expired.") {
            removeToken();
            window.location.replace("/");
          }
          return Toastify.Error(obj[key][0]);
        }
      }
    }
  } else {
    return Toastify.Error("Something going wrong, Try again.");
  }
};

/* Get permissions from JWT token */
// export const getPermissionFromToken = (token: string): boolean => {
//   const decode = jwt_decode(token) as jwtTokenType;
//   if (decode && decode.role && decode.role === "admin") {
//     return true;
//   }

//   return false;
// };

export const getPermissionFromToken = (token: string) => {
  let permissions: string[] = [];

  const decode = jwt_decode(token) as jwtTokenType;
  if (decode && decode.permissions && decode.permissions.length) {
    const arrLength = decode.permissions.length;
    for (let i = 0; i < arrLength; i++) {
      if (decode.permissions[i] === "all") {
        permissions.push("all");
        break;
      } else {
        permissions = decode.permissions;
      }
    }
  }

  return permissions;
};

/* Generate array from integer number */
export const arrayFromNumber = (data: number) => {
  const array = [];
  for (let i = 0; i < data; i++) array.push(i);
  return array;
};

/* Gender data */
export const genderList = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Other",
    value: "Other",
  },
];

/* Date parse conversion */
export const dateparse = (date: any) => {
  const zeroFill = (i: any) => {
    return i < 9 ? `0${i}` : i;
  };

  const newDate = new Date(date);

  const day = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  return `${zeroFill(day)}-${zeroFill(month)}-${zeroFill(year)}`;
};

/* Time parse conversion */
export const timeparse = (date: any) => {
  const newDate = new Date(date);

  const hour = newDate.getHours();
  const minute = newDate.getMinutes();

  return `${hour}:${minute}`;
};

/* Time AM/PM conversion */
export const toAMPM = (data: string) => {
  const timeString12hr = new Date(
    "1970-01-01T" + data + "Z"
  ).toLocaleTimeString("en-US", {
    timeZone: "UTC",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  });

  return timeString12hr;
};
