import React, { useState } from "react";
import { Images } from "utils/images";
import { Card } from "components/card";
// import { NetworkServices } from "network";
// import { Toastify } from "components/toastify";
import { networkErrorHandeller } from "utils/helper";
// import { useNavigate, useParams } from "react-router-dom";
import { OtpVerificationForm } from "components/form/opt-verification.form";

export const OtpVerification: React.FC = (): JSX.Element => {
  // const navigate = useNavigate();
  // const { employeeid } = useParams();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isResendLoading, setResendLoading] = useState<boolean>(false);

  /* handle resend */
  const handleResendOtp = async () => {
    try {
      setResendLoading(true);
      // const formData = {
      //   employee_id: employeeid || "",
      // };
      // const response = await NetworkServices.Authentication.reset(formData);
      // if (response && response.status === 200) {
      //   Toastify.Success(response.data.message);
      // }

      setResendLoading(false);
    } catch (error: any) {
      setResendLoading(false);
      networkErrorHandeller(error);
    }
  };

  /* handle submit */
  const handleSubmit = async (data: any) => {
    try {
      setLoading(true);

      // const formData = {
      //   employee_id: employeeid || "",
      //   otp_code: data,
      // };

      // const response = await NetworkServices.Authentication.verifyOtp(formData);
      // if (response && response.status === 200) {
      //   Toastify.Success(response.data.message);
      //   navigate(`/set-password/${employeeid}/${data}`);
      // }

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      networkErrorHandeller(error);
    }
  };

  return (
    <div className="grid h-screen place-items-center p-3">
      <div className="mx-auto w-full sm:w-[500px]">
        <Card className="p-6">
          <div className="text-center mb-8">
            <img
              src={Images.Logo}
              alt="Logo"
              className="w-[180px] h-[80px] mx-auto mb-2"
            />

            <p className="text-2xl font-bold">OTP Verification</p>
            <p className="text-center text-sm font-medium text-muted">
              We have sent a verification code to 01XXXXXXXXX, put the OTP to
              complete verification process.
            </p>
          </div>

          <OtpVerificationForm
            loading={isLoading}
            resendLoading={isResendLoading}
            resendOtp={handleResendOtp}
            onSubmit={(data) => handleSubmit(data)}
          />
        </Card>
      </div>
    </div>
  );
};
