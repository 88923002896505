import { useCallback, useEffect, useState } from "react";
import { Card } from "components/card";
import { NetworkServices } from "network";
import { TbUnlink } from "react-icons/tb";
import { NotFound } from "components/404";
import { useParams } from "react-router-dom";
import { ICourse } from "types/course.types";
import { NetworkError } from "components/501";
import { PageHeader } from "components/page-header";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { dateparse, networkErrorHandeller, toAMPM } from "utils/helper";
import { BsArrowRightCircle, BsClock, BsBook } from "react-icons/bs";
import { CourseLoader } from "components/ghostloader/course.ghostloader";
import HTMLParse from "html-react-parser";

export const CourseShow: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const [data, setData] = useState<ICourse | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [serverError, setServerError] = useState<boolean>(false);

  /* get specific resource data */
  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await NetworkServices.Course.show(id || "");
      if (response && response.status === 200) {
        setData(response?.data?.data);
      }
      setLoading(false);
    } catch (error) {
      if (error) {
        setLoading(false);
        setServerError(true);
        networkErrorHandeller(error);
      }
    }
  }, [id]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div>
      <PageHeader
        title="Course information"
        buttonIcon={<HiOutlineChevronLeft size={18} />}
        redirectPath="/course"
      />

      {isLoading && !serverError && !data ? (
        <Card>
          <CourseLoader />
        </Card>
      ) : null}

      {!isLoading && !data && serverError ? <NetworkError /> : null}
      {!isLoading && !data && !serverError ? (
        <NotFound message="Course not found." />
      ) : null}

      {!isLoading && !serverError && data ? (
        <Card>
          <p className="text-base font-medium mb-3">{data.name}</p>

          {/* Banner & small information cards start */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-6">
            <div
              className="w-full h-[250px] rounded-lg bg-cover bg-no-repeat"
              style={{ backgroundImage: `url(${data.banner})` }}
            />
            <div className="w-full">
              <div className="grid grid-cols-2 gap-4">
                {/* Course fee */}
                <div className="w-full rounded-lg text-center px-2 py-8 bg-primary-transparent">
                  <p className="text-xl font-light text-primary">
                    {data.course_fee} tk.
                  </p>
                  <p className="text-sm font-medium text-primary">Course fee</p>
                </div>

                {/* Total class */}
                <div className="w-full rounded-lg text-center px-2 py-8 bg-primary-transparent">
                  <p className="text-xl font-light text-primary">
                    {data.total_class}
                  </p>
                  <p className="text-sm font-medium text-primary">
                    Total Class
                  </p>
                </div>

                {/* Total enrolled */}
                <div className="w-full rounded-lg text-center px-2 py-8 bg-primary-transparent">
                  <p className="text-xl font-light text-primary">1000</p>
                  <p className="text-sm font-medium text-primary">
                    Total enrolled
                  </p>
                </div>

                {/* Earnings */}
                <div className="w-full rounded-lg text-center px-2 py-8 bg-primary-transparent">
                  <p className="text-xl font-light text-primary">1000 tk.</p>
                  <p className="text-sm font-medium text-primary">Earnings</p>
                </div>
              </div>
            </div>
          </div>
          {/* Banner & small information cards end */}

          {/* Instructor information start */}
          <div className="flex gap-2 mb-4">
            <BsArrowRightCircle
              size={20}
              className="mt-[1px] text-primary-disabled"
            />
            <div>
              <p className="text-sm font-medium">Instructor</p>
              <p className="text-sm text-muted">{data.instructor}</p>
            </div>
          </div>
          {/* Instructor information end */}

          {/* Category information start */}
          <div className="flex gap-2 mb-4">
            <BsArrowRightCircle
              size={20}
              className="mt-[1px] text-primary-disabled"
            />
            <div>
              <p className="text-sm font-medium">Category</p>
              <p className="text-sm text-muted">{data.category.name}</p>
            </div>
          </div>
          {/* Category information end */}

          {/* Class schedules information start */}
          <div className="flex gap-2 mb-4">
            <BsArrowRightCircle
              size={20}
              className="mt-[1px] text-primary-disabled"
            />
            <div>
              <p className="text-sm font-medium">Class schedules</p>

              {data.schedules.map((schedule, i) => (
                <div className="py-3" key={i}>
                  <div className="inline-flex gap-2">
                    <BsBook size={15} className="text-muted" />
                    <p className="text-xs text-muted">Class {i + 1}</p>
                  </div>
                  <br />
                  <div className="inline-flex gap-2">
                    <BsClock size={15} className="text-muted" />
                    <p className="text-xs text-primary">
                      {dateparse(schedule.date)} ({toAMPM(schedule.time)})
                    </p>
                  </div>
                  <br />
                  <div className="inline-flex gap-2">
                    <TbUnlink size={16} className="text-muted" />
                    <p className="text-xs text-muted">{schedule.class_link}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Class schedules information end */}

          {/* Description start */}
          <div className="flex gap-2 mb-4">
            <BsArrowRightCircle
              size={20}
              className="mt-[1px] text-primary-disabled"
            />
            <div>
              <p className="text-sm font-medium">Description</p>
              <p className="leading-loose text-muted">
                {HTMLParse(data.description)}
              </p>
            </div>
          </div>
          {/* Description end */}
        </Card>
      ) : null}
    </div>
  );
};
