import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import { Disclosure } from "@headlessui/react";
import { isActivePath } from "utils/helper";
import { permittedRoutes } from "routes";
import { IRoute } from "types/routes";

export const SidebarMenuItems: React.FC = (): JSX.Element => {
  const location = useLocation();

  return (
    <>
      {permittedRoutes().map((item, i) =>
        item.in_drawer ? (
          !item.multi_menu ? (
            <Link key={i} to={`/${item.path}`}>
              <button
                type="button"
                className={`capitalize text-sm font-medium pl-3 pr-1 py-3 w-full inline-flex justify-between rounded-xl transition-all text-muted hover:text-black ${
                  isActivePath(location.pathname, item.path)
                    ? "bg-primary-transparent text-primary hover:!text-primary"
                    : ""
                }`}
              >
                {item.path}
                <BsChevronRight size={15} className="mt-[2px]" />
              </button>
            </Link>
          ) : (
            <MultiMenu
              key={i}
              parentPath={item.path}
              children={item.children}
            />
          )
        ) : null
      )}
    </>
  );
};

/* Multi menu items */
type PropsTypes = {
  parentPath: string;
  children?: IRoute[];
};

const MultiMenu: React.FC<PropsTypes> = (props: PropsTypes): JSX.Element => {
  const location = useLocation();
  const [isOpen, setOpen] = useState<boolean>(false);

  /* Handle open */
  const handleOpen = () => setOpen(!isOpen);

  return (
    <Disclosure>
      <Disclosure.Button
        className={`capitalize text-sm font-medium pl-3 pr-1 py-3 w-full inline-flex justify-between rounded-xl transition-all text-muted hover:text-black ${
          isActivePath(location.pathname, props.parentPath)
            ? "bg-primary-transparent text-primary hover:!text-primary"
            : ""
        }`}
        onClick={handleOpen}
      >
        {props.parentPath}
        <BsChevronRight
          size={15}
          className={`${isOpen ? "rotate-90" : ""} transition-all mt-[2px]`}
        />
      </Disclosure.Button>
      <Disclosure.Panel className={"p-3"}>
        {props.children && props.children.length > 0
          ? props.children.map((item, i) =>
              item.in_drawer ? (
                <Link key={i} to={`/${props.parentPath}/${item.path}`}>
                  <button
                    type="button"
                    className={`capitalize text-sm font-medium pl-3 pr-1 py-3 w-full inline-flex justify-between rounded-xl transition-all text-muted hover:text-black ${
                      isActivePath(location.pathname, item.path)
                        ? "bg-primary-transparent text-primary hover:!text-primary"
                        : ""
                    }`}
                  >
                    {item.path}
                    <BsChevronRight size={15} className="mt-[2px]" />
                  </button>
                </Link>
              ) : null
            )
          : null}
      </Disclosure.Panel>
    </Disclosure>
  );
};
