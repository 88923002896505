import { privateRequest } from "config/axios.config";
import { IHeadingBannerCreate } from "types/heading-banner";
import { PaginationParamsTypes } from "types/pagination.types";

/* List of resources */
export const index = async (reqParams: PaginationParamsTypes) => {
  return await privateRequest.get(`api/admin/banner`, {
    params: { ...reqParams },
  });
};

/* Store resource */
export const store = async (data: IHeadingBannerCreate) => {
  return await privateRequest.post(`api/admin/banner`, data);
};

/* Destroy specific resource */
export const destroy = async (id: string) => {
  return await privateRequest.delete(`api/admin/banner/${id}`);
};
