import { useCallback, useEffect, useState } from "react";
import { Card } from "components/card";
import { NetworkServices } from "network";
import { NetworkError } from "components/501";
import { IProfile } from "types/profile.types";
import { PageHeader } from "components/page-header";
import { networkErrorHandeller } from "utils/helper";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { AiOutlineWhatsApp, AiOutlineMail } from "react-icons/ai";
import { ProfileLoader } from "components/ghostloader/profile.ghostloader";
import { NotFound } from "components/404";

export const ProfileIndex: React.FC = (): JSX.Element => {
  const [data, setData] = useState<IProfile | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [serverError, setServerError] = useState<boolean>(false);

  /* Fetch data */
  const fetchData = useCallback(async () => {
    try {
      setData(null);
      setLoading(true);
      const response = await NetworkServices.Profile.me();
      if (response && response.status === 200) {
        setData(response?.data?.data);
      }
      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        setServerError(true);
        networkErrorHandeller(error);
      }
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <PageHeader
        title="Your profile"
        buttonIcon={<HiOutlineChevronLeft size={18} />}
        redirectPath="/dashboard"
      />

      {isLoading && !serverError && !data ? (
        <Card className="lg:p-5">
          <ProfileLoader />
        </Card>
      ) : null}
      {!isLoading && !data && serverError ? <NetworkError /> : null}
      {!isLoading && !data && !serverError ? (
        <NotFound message="Profile information not found." />
      ) : null}

      {/* Profile information container */}
      {!isLoading && !serverError && data ? (
        <Card className="lg:p-5 mb-4">
          <p className="text-xl font-bold mb-3 capitalize text-primary">
            {data.name}
          </p>
          <p className="text-muted text-sm inline-flex gap-3 mb-3">
            <AiOutlineMail size={20} />
            {data.email}
          </p>
          <br />
          <p className="text-muted text-sm inline-flex gap-3 mb-3">
            <AiOutlineWhatsApp size={20} />
            {data.phone}
          </p>
        </Card>
      ) : null}
    </>
  );
};
