import { useState } from "react";
import OtpInput from "react18-input-otp";
import { PrimaryButton } from "components/button";

type PropsTypes = {
  loading: boolean;
  resendLoading: boolean;
  onSubmit: (data: any) => void;
  resendOtp: () => void;
};

export const OtpVerificationForm: React.FC<PropsTypes> = (
  props: PropsTypes
): JSX.Element => {
  const [otpValue, setOtpValue] = useState<string>("");
  const [isError, setError] = useState<boolean>(false);

  /* handle input change */
  const handleInputChange = (enteredOtp: string) => {
    setOtpValue(enteredOtp);
    setError(false);
  };

  /* handle form submit */
  const onSubmit = (event: any) => {
    event.preventDefault();

    if (otpValue.length < 4) {
      return setError(true);
    }

    props.onSubmit(otpValue);
  };

  return (
    <form onSubmit={onSubmit}>
      <div>
        <OtpInput
          value={otpValue}
          onChange={handleInputChange}
          numInputs={4}
          isInputNum
          isDisabled={props.loading || props.resendLoading}
          hasErrored={isError}
          containerStyle="!justify-center !mb-7"
          separator={<span className="w-[15px] sm:w-[20px]" />}
          inputStyle="border !h-[55px] !w-[55px] rounded-lg !mx-auto font-medium text-lg focus:outline-none focus-visible:none"
          errorStyle="border border-danger"
        />
      </div>

      {/* Submit button */}
      <div className="text-center mb-6">
        <PrimaryButton
          type="submit"
          disabled={props.loading || props.resendLoading}
          className="!px-8"
        >
          {props.loading ? "Loading..." : "Verify"}
        </PrimaryButton>
      </div>

      <div className="flex justify-center text-center gap-3">
        <p className="text-muted text-sm">{`Didn’t get code?`}</p>
        <button
          type="button"
          className="text-sm font-medium text-primary disabled:text-muted"
          disabled={props.loading || props.resendLoading}
          onClick={props.resendOtp}
        >
          {props.resendLoading ? "Sending..." : "Resend"}
        </button>
      </div>
    </form>
  );
};
