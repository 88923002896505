import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HiPlus, HiOutlineTrash } from "react-icons/hi";
import { Card } from "components/card";
import { DataTable } from "components/table";
import { IAdmin } from "types/admin.types";
import { IRDataColumns } from "types/datatable.types";
import { NetworkServices } from "network";
import { networkErrorHandeller } from "utils/helper";
import { WhiteCircleButton } from "components/button";
import { MdEdit } from "react-icons/md";
import { PageHeader } from "components/page-header";
import { Toastify } from "components/toastify";
import { ConfirmationModal } from "components/modal/confirm.modal";

export const AdminIndex: React.FC = (): JSX.Element => {
  const [data, setData] = useState<IAdmin[] | []>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [willDelete, setWillDelete] = useState<any>({
    id: null,
    show: false,
    loading: false,
  });

  /* Fetch data */
  const fetchData = useCallback(async (page: number) => {
    try {
      setLoading(true);
      const response = await NetworkServices.Admin.index({
        page,
      });
      if (response && response.status === 200) {
        setData(response?.data?.data?.data);
        setTotalRows(response?.data?.data?.total);
      }
      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        networkErrorHandeller(error);
      }
    }
  }, []);

  useEffect(() => {
    fetchData(1);
  }, [fetchData]);

  /* handle paginate page change */
  const handlePageChange = (page: number) => fetchData(page);

  /* handle delete */
  const handleDelete = async () => {
    try {
      setWillDelete({ ...willDelete, loading: true });
      const response = await NetworkServices.Admin.destroy(willDelete.id || "");

      if (response && response.status === 200) {
        fetchData(1);
        Toastify.Success(response.data.message);
      }

      setWillDelete({ id: null, show: false, loading: false });
    } catch (error: any) {
      if (error) {
        setWillDelete({ id: null, show: false, loading: false });
        networkErrorHandeller(error);
      }
    }
  };

  /* data columns */
  const columns: IRDataColumns[] = [
    {
      name: "ID",
      maxWidth: "50px",
      selector: (row) => row.id,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Action",
      maxWidth: "100px",
      cell: (row) => (
        <div className="flex gap-1">
          <Link to={`/admin/edit/${row.id}`}>
            <WhiteCircleButton type="button">
              <MdEdit size={18} />
            </WhiteCircleButton>
          </Link>
          <WhiteCircleButton
            type="button"
            onClick={() =>
              setWillDelete({ id: row.id, show: true, loading: false })
            }
          >
            <HiOutlineTrash size={18} />
          </WhiteCircleButton>
        </div>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        title="Admin list"
        redirectPath="/admin/create"
        buttonIcon={<HiPlus size={18} />}
      />

      <Card>
        <DataTable
          data={data}
          columns={columns}
          loading={isLoading}
          pagination={true}
          paginationServer={true}
          totalRows={totalRows}
          handlePageChange={handlePageChange}
          noDataMessage="No data found."
        />
      </Card>

      {/* Delete confirmation modal */}
      <ConfirmationModal
        show={willDelete.show}
        title="Are you sure you want to delete?"
        loading={willDelete.loading}
        onHide={() => setWillDelete({ id: null, loading: false, show: false })}
        onConfirm={handleDelete}
      />
    </div>
  );
};
