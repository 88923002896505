import { publicRequest } from "config/axios.config";

/* Login request */
type LoginDataType = {
  email: string;
  password: string;
};
export const login = async (data: LoginDataType) => {
  return await publicRequest.post(`api/auth/login`, data);
};

/* Reset request */
type ResetDataType = {
  email: string;
};
export const reset = async (data: ResetDataType) => {
  return await publicRequest.post(`api/auth/reset`, data);
};

/* Verify OTP request */
type VerifyOTPDataType = {
  email: string;
  otp_code: string;
};
export const verifyOtp = async (data: VerifyOTPDataType) => {
  return await publicRequest.post(`api/auth/verify-otp`, data);
};

/* Set password request */
type SetPwdDataType = {
  email: string;
  otp_code: string;
};
export const setPassword = async (data: SetPwdDataType) => {
  return await publicRequest.post(`api/auth/set-password`, data);
};
