import React, { useCallback, useEffect, useState } from "react";
import { Card } from "components/card";
import { BsBook } from "react-icons/bs";
import { MdAttachMoney } from "react-icons/md";
import { HiOutlineNewspaper, HiOutlineUsers } from "react-icons/hi";
import { IDashboard } from "types/dashboard.types";
import { NetworkServices } from "network";
import { networkErrorHandeller } from "utils/helper";
import { DashboardLoader } from "components/ghostloader/dashboard.ghostloader";
import { NetworkError } from "components/501";
import { NotFound } from "components/404";

export const Dashboard: React.FC = (): JSX.Element => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<IDashboard | null>(null);
  const [serverError, setServerError] = useState<boolean>(false);

  /* Fetch data */
  const fetchData = useCallback(async () => {
    try {
      setData(null);
      setLoading(true);
      const response = await NetworkServices.Dashboard.index();
      if (response && response.status === 200) {
        setData(response?.data?.data);
      }
      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        setServerError(true);
        networkErrorHandeller(error);
      }
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      {isLoading && !serverError && !data ? (
        <Card className="lg:p-5">
          <DashboardLoader />
        </Card>
      ) : null}
      {!isLoading && !data && serverError ? <NetworkError /> : null}
      {!isLoading && !data && !serverError ? (
        <NotFound message="Dashboard information not found." />
      ) : null}

      {/* Dashboard information container */}
      {!isLoading && !serverError && data ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-4 md:gap-5 2xl:gap-5">
          {/* Courses */}
          <Card className="!p-5">
            <div className="flex justify-between mb-5">
              <BsBook size={22} className="text-primary" />
              <p className="font-bold uppercase text-sm text-muted">Courses</p>
            </div>
            <div className="text-right">
              <p className="font-bold uppercase text-4xl">{data.courses}</p>
            </div>
          </Card>

          {/* Blog */}
          <Card className="!p-5">
            <div className="flex justify-between mb-5">
              <HiOutlineNewspaper size={22} className="text-primary" />
              <p className="font-bold uppercase text-sm text-muted">Blog</p>
            </div>
            <div className="text-right">
              <p className="font-bold uppercase text-4xl">{data.blogs}</p>
            </div>
          </Card>

          {/* Users */}
          <Card className="!p-5">
            <div className="flex justify-between mb-5">
              <HiOutlineUsers size={22} className="text-primary" />
              <p className="font-bold uppercase text-sm text-muted">Users</p>
            </div>
            <div className="text-right">
              <p className="font-bold uppercase text-4xl">{data.users}</p>
            </div>
          </Card>

          {/* Total Payment */}
          <Card className="!p-5">
            <div className="flex justify-between mb-5">
              <MdAttachMoney size={22} className="text-primary" />
              <p className="font-bold uppercase text-sm text-muted">
                Total Payment
              </p>
            </div>
            <div className="text-right">
              <p className="font-bold uppercase text-4xl">{data.payments}</p>
            </div>
          </Card>
        </div>
      ) : null}
    </div>
  );
};
