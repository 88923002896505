import { privateRequest } from "config/axios.config";
import { PaginationParamsTypes } from "types/pagination.types";
import { IPaymentUpdate } from "types/payment.types";

/* List of resources */
export const index = async (reqParams: PaginationParamsTypes) => {
  return await privateRequest.get(`api/admin/payment`, {
    params: { ...reqParams },
  });
};

/* Show specific resource */
export const show = async (id: string) => {
  return await privateRequest.get(`api/admin/payment/${id}`);
};

/* Update specific resource */
export const update = async (id: string, data: IPaymentUpdate) => {
  return await privateRequest.put(`api/admin/payment/${id}`, data);
};
