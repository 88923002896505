import { useCallback, useEffect, useState } from "react";
import { Card } from "components/card";
import { IBlog } from "types/blog.types";
import { NetworkServices } from "network";
import { useParams } from "react-router-dom";
import { PageHeader } from "components/page-header";
import { networkErrorHandeller } from "utils/helper";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { FormLoader } from "components/ghostloader/form.ghostloader";
import { NetworkError } from "components/501";
import { NotFound } from "components/404";
import HTMLParse from "html-react-parser";

export const BlogShow: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const [data, setData] = useState<IBlog | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [serverError, setServerError] = useState<boolean>(false);

  /* get specific resource data */
  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await NetworkServices.Blog.show(id || "");
      if (response && response.status === 200) {
        setData(response?.data?.data);
      }
      setLoading(false);
    } catch (error) {
      if (error) {
        setLoading(false);
        setServerError(true);
        networkErrorHandeller(error);
      }
    }
  }, [id]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div>
      <PageHeader
        title="Blog information"
        buttonIcon={<HiOutlineChevronLeft size={18} />}
        redirectPath="/blog"
      />

      {isLoading && !serverError && !data ? (
        <Card className="lg:p-5">
          <FormLoader />
        </Card>
      ) : null}

      {!isLoading && !data && serverError ? <NetworkError /> : null}
      {!isLoading && !data && !serverError ? (
        <NotFound message="Blog not found." />
      ) : null}

      {!isLoading && !serverError && data ? (
        <Card className="lg:p-6">
          {/* Header */}
          <div className="mb-4">
            <p className="text-lg font-bold mb-0 leading-relaxed text-gray-600">
              {data.title}
            </p>
            {/* <p className="text-base text-gray-500">{props.created_at}</p> */}
          </div>

          {/* Banner */}
          <div className="overflow-hidden max-h-[450px] rounded-xl mb-4">
            <img src={data.banner} alt="banner" className="w-full" />
          </div>

          {/* Content */}
          <div className="px-0">
            <p className="text-base leading-loose">
              {HTMLParse(data.description)}
            </p>
          </div>
        </Card>
      ) : null}
    </div>
  );
};
