import React, { useCallback, useState, useEffect } from "react";
import { Card } from "components/card";
import { PageHeader } from "components/page-header";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { PermissionForm } from "components/form/permission.form";
import {
  IRPermissionShow,
  IRPermissionCreateUpdate,
  IRRole,
} from "types/permission.types";
import { networkErrorHandeller } from "utils/helper";
import { NetworkServices } from "network";
import { FormLoader } from "components/ghostloader/form.ghostloader";
import { Toastify } from "components/toastify";
import { useParams } from "react-router-dom";
import { NetworkError } from "components/501";
import { NotFound } from "components/404";

export const PermissionEdit: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const [roles, setRoles] = useState<IRRole[] | []>([]);
  const [data, setData] = useState<IRPermissionShow | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isUpdated, setUpdated] = useState<boolean>(false);
  const [serverError, setServerError] = useState<boolean>(false);

  /** fetch role list data */
  const fetchData = useCallback(async () => {
    try {
      const roleResponse = await NetworkServices.Permission.roles();
      const permissionResponse = await NetworkServices.Permission.show(
        id || ""
      );
      if (
        roleResponse &&
        permissionResponse &&
        permissionResponse.status === 200 &&
        roleResponse.status === 200
      ) {
        let permissionMasterData = permissionResponse?.data?.data;
        setRoles(roleResponse?.data?.data);

        if (
          permissionMasterData &&
          permissionMasterData.roles &&
          permissionMasterData.roles.length
        ) {
          const roleArr: IRRole[] = [];
          for (let i = 0; i < permissionMasterData.roles.length; i++) {
            const element = permissionMasterData.roles[i];
            roleArr.push({
              label: element,
              value: element,
            });
          }

          permissionMasterData = { ...permissionMasterData, roles: roleArr };
        }

        setData(permissionMasterData);
      }
      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        setServerError(true);
        networkErrorHandeller(error);
      }
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  /** Handle submission */
  const handleSubmit = async (value: IRPermissionCreateUpdate) => {
    try {
      setUpdated(true);

      const response = await NetworkServices.Permission.update(id || "", value);
      if (response && response.status === 200) {
        Toastify.Success(response.data.message);
      }
      setUpdated(false);
    } catch (error: any) {
      setUpdated(false);
      networkErrorHandeller(error);
    }
  };

  return (
    <div>
      <PageHeader
        title="Edit permission"
        buttonIcon={<HiOutlineChevronLeft size={18} />}
        redirectPath="/permission"
      />

      <Card className="lg:p-6 !overflow-visible">
        {/* Preloader preview */}
        {isLoading && !serverError && !data ? (
          <Card className="lg:p-5">
            <FormLoader />
          </Card>
        ) : null}

        {/* Server error preview */}
        {!isLoading && !data && serverError ? <NetworkError /> : null}

        {/* Not found preview */}
        {!isLoading && !data && !serverError ? (
          <NotFound message="Permission not found." />
        ) : null}

        {/* Data form preview */}
        {!isLoading && !serverError && data ? (
          <PermissionForm
            data={data}
            loading={isUpdated}
            roleList={roles}
            onSubmit={handleSubmit}
          />
        ) : null}
      </Card>
    </div>
  );
};
