import { useForm } from "react-hook-form";
import { ICategory } from "types/category.types";
import { PrimaryButton } from "components/button";
import { TextInput, TextAreaInput } from "components/input";

type PropsTypes = {
  data: ICategory | null;
  loading: boolean;
  onSubmit: (data: any) => void;
};

export const CategoryForm: React.FC<PropsTypes> = (
  props: PropsTypes
): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /* Handle form submit */
  const onSubmit = (data: any) => props.onSubmit(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Name input control */}
      <div className="mb-6">
        <TextInput
          label="Category name"
          name="name"
          type="text"
          placeholder="Enter category name"
          control={control}
          error={errors.name && errors.name.message}
          defaultvalue={props.data?.name || ""}
          rules={{ required: "Category name is required" }}
        />
      </div>

      {/* Description input control */}
      <div className="mb-2">
        <TextAreaInput
          label="Category description"
          name="description"
          type="text"
          placeholder="Write category description"
          control={control}
          error={errors.description && errors.description.message}
          defaultvalue={props.data?.description || ""}
          rows={4}
          rules={{ required: "Category description is required" }}
        />
      </div>

      {/* Submit button */}
      <div className="text-center mt-8 mb-4">
        <PrimaryButton type="submit" className="!px-7" disabled={props.loading}>
          {props.loading ? "Loading..." : "Submit"}
        </PrimaryButton>
      </div>
    </form>
  );
};
