export const ProfileLoader: React.FC = (): JSX.Element => {
  return (
    <div className="lg:flex gap-6 animate-pulse">
      <div className="w-[100px] h-[100px] rounded-full mx-auto lg:mx-0 mb-4 lg:mb-0 bg-slate-200" />

      <div className="text-center lg:text-left lg:pt-6">
        <div className="h-3 mx-auto lg:mx-0 rounded-full mb-2 bg-slate-200 w-40" />
        <div className="h-3 mx-auto lg:mx-0 rounded-full bg-slate-200 w-32 mb-2" />
      </div>
      <div className="text-center lg:text-left lg:pt-6">
        <div className="h-3 mx-auto lg:mx-0 rounded-full mb-2 bg-slate-200 w-40" />
        <div className="h-3 mx-auto lg:mx-0 rounded-full bg-slate-200 w-32" />
      </div>
    </div>
  );
};
