import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { BsCheckCircle } from "react-icons/bs";
import { WhiteButton } from "components/button";

type PropsTypes = {
  loading: boolean;
  onSubmit: (data: any) => void;
};

const items = [
  {
    value: "Pending",
    description: "Make payment status to pending.",
  },
  {
    value: "Accepted",
    description: "Accept payment status to user accessible.",
  },
];

export const PaymentStatusForm: React.FC<PropsTypes> = (
  props: PropsTypes
): JSX.Element => {
  const [selected, setSelected] = useState(items[0]);

  /** handle submission */
  const handleSubmit = () => {
    props.onSubmit({ payment_status: selected.value });
  };

  return (
    <div className="w-full px-4 py-12">
      <div className="mx-auto w-full max-w-md">
        <RadioGroup value={selected} onChange={setSelected}>
          <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
          <div className="space-y-4">
            {items.map((item, i) => (
              <RadioGroup.Option
                key={i}
                value={item}
                className={({ active, checked }) =>
                  `${
                    active
                      ? "border border-secondary"
                      : "border border-secondary"
                  }
                  ${checked ? "bg-secondary text-white" : "bg-white"}
                    relative flex cursor-pointer rounded-lg px-5 py-4 focus:outline-none`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-medium  ${
                              checked ? "text-white" : "text-gray-900"
                            }`}
                          >
                            {item.value}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={`inline ${
                              checked ? "text-sky-100" : "text-gray-400"
                            }`}
                          >
                            <span>{item.description}</span>
                          </RadioGroup.Description>
                        </div>
                      </div>
                      {checked && (
                        <div className="shrink-0 text-white">
                          <BsCheckCircle size={25} />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>

        <div className="text-center pt-8">
          <WhiteButton
            type="button"
            onClick={handleSubmit}
            disabled={props.loading}
          >
            {props.loading ? "Loading..." : "Submit"}
          </WhiteButton>
        </div>
      </div>
    </div>
  );
};
