import { privateRequest } from "config/axios.config";
import { IAdminStore, IAdminUpdate } from "types/admin.types";
import { PaginationParamsTypes } from "types/pagination.types";

/* List of resources */
export const index = async (reqParams: PaginationParamsTypes) => {
  return await privateRequest.get(`api/admin/admin`, {
    params: { ...reqParams },
  });
};

/* Store resource */
export const store = async (data: IAdminStore) => {
  return await privateRequest.post(`api/admin/admin`, data);
};

/* Show specific resource */
export const show = async (id: string) => {
  return await privateRequest.get(`api/admin/admin/${id}`);
};

/* Update specific resource */
export const update = async (id: string, data: IAdminUpdate) => {
  return await privateRequest.put(`api/admin/admin/${id}`, data);
};

/* Destroy specific resource */
export const destroy = async (id: string) => {
  return await privateRequest.delete(`api/admin/admin/${id}`);
};
