type PropsTypes = {
  className?: string;
  children: React.ReactNode;
};

export const Card: React.FC<PropsTypes> = (props: PropsTypes): JSX.Element => {
  return (
    <>
      <div
        className={`rounded-lg p-4 overflow-hidden bg-white ${props.className}`}
      >
        {props.children}
      </div>
    </>
  );
};
