import * as Authentication from "network/auth.network";
import * as Profile from "network/profile.network";
import * as Dashboard from "network/dashboard.network";
import * as HeadingBanner from "network/heading-banner.network";
import * as TrainingBanner from "network/training-banner.network";
import * as Admin from "network/admin.network";
import * as Permission from "network/permission.network";
import * as User from "network/user.network";
import * as Category from "network/category.network";
import * as Course from "network/course.network";
import * as Payment from "network/payment.network";
import * as Blog from "network/blog.network";
import * as Setting from "network/setting.network";
import * as Team from "network/team.network";
import * as Subscription from "network/subscription.network";
import * as FileUpload from "network/fileuplod.network";

export const NetworkServices = {
  Authentication,
  Profile,
  Dashboard,
  HeadingBanner,
  TrainingBanner,
  Admin,
  Permission,
  User,
  Category,
  Course,
  Payment,
  Blog,
  Setting,
  Team,
  Subscription,
  FileUpload,
};
