import React, { useEffect } from "react";
import { IBlog } from "types/blog.types";
import { useForm } from "react-hook-form";
import { PrimaryButton } from "components/button";
import { FileUploader } from "components/file-uploader";
import { TextInput } from "components/input";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

type PropsTypes = {
  loading: boolean;
  data: IBlog | null;
  formType: "create" | "edit";
  onSubmit: (data: any) => void;
};

export const BlogForm: React.FC<PropsTypes> = (
  props: PropsTypes
): JSX.Element => {
  const {
    control,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();
  const watchDescription = watch("description");

  /* Handle set value */
  const handleSetValue = ({ key, value }: { key: string; value: string }) => {
    setValue(`${key}`, value);
    clearErrors(key);
  };

  useEffect(() => {
    if (props.data) {
      setValue("description", props.data.description);
    }
  }, [props, setValue]);

  /* Handle form submit */
  const onSubmit = (data: any) => {
    if (!data.description) {
      return setError("description", {
        type: "custom",
        message: "Blog description is required.",
      });
    }

    if (props.formType === "create" && !data.banner) {
      return setError("banner", {
        type: "custom",
        message: "Banner is required.",
      });
    }

    if (props.formType === "create") {
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("description", data.description);
      formData.append("banner", data.banner);

      props.onSubmit(formData);
    }

    if (props.formType === "edit") {
      const formData = {
        ...data,
      };

      props.onSubmit(formData);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Title input control */}
      <div className="mb-6">
        <TextInput
          label="Blog title"
          name="title"
          type="text"
          placeholder="Enter blog title"
          control={control}
          error={errors.title && errors.title.message}
          defaultvalue={props.data?.title || ""}
          rules={{ required: "Blog title is required" }}
        />
      </div>
      

      {/* Description input control */}
      <div className="mb-6">
        {errors.description && errors.description.message ? (
          <p className="text-sm mb-1 text-danger">
            Blog description is required.
          </p>
        ) : (
          <p className="text-sm mb-1 text-gray-500">Blog description</p>
        )}
        <ReactQuill
          theme="snow"
          placeholder="Write blog description..."
          value={watchDescription || ""}
          onChange={(data) =>
            handleSetValue({ key: "description", value: data })
          }
        />
      </div>

      {/* Banner input */}
      {props.formType === "create" ? (
        <div className="mb-6">
          <p className="text-sm mb-2 text-muted">Banner size (1920X550)</p>
          <div className="w-full sm:w-72">
            <FileUploader
              isLoading={false}
              defaultValue={props.data?.banner || null}
              error={errors.banner && errors.banner.message}
              onUploded={(data) =>
                handleSetValue({ key: "banner", value: data })
              }
            />
          </div>
        </div>
      ) : null}

      {/* Submit button */}
      <div className="text-center mt-8 mb-4">
        <PrimaryButton type="submit" className="!px-7" disabled={props.loading}>
          {props.loading ? "Loading..." : "Submit"}
        </PrimaryButton>
      </div>
    </form>
  );
};
