import { useCallback, useEffect, useState } from "react";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { PageHeader } from "components/page-header";
import { Card } from "components/card";
import { NetworkServices } from "network";
import { useParams } from "react-router-dom";
import { NetworkError } from "components/501";
import { IAdminShow } from "types/admin.types";
import { networkErrorHandeller } from "utils/helper";
import { FormLoader } from "components/ghostloader/form.ghostloader";
import { AdminForm } from "components/form/admin.form";
import { Toastify } from "components/toastify";
import { NotFound } from "components/404";
import { IROption } from "types/permission.types";

export const AdminEdit: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const [data, setData] = useState<IAdminShow | null>(null);
  const [permissions, setPermissions] = useState<IROption[] | []>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [serverError, setServerError] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);

  /* Fetch data */
  const fetchData = useCallback(async () => {
    try {
      const response = await NetworkServices.Admin.show(id || "");
      const permissionResponse = await NetworkServices.Permission.all();
      if (
        response &&
        permissionResponse &&
        permissionResponse.status === 200 &&
        response.status === 200
      ) {
        setData(response?.data?.data);
        const permissionMasterData = permissionResponse?.data?.data;
        const dataArr: IROption[] = [];

        for (let i = 0; i < permissionMasterData.length; i++) {
          const element = permissionMasterData[i];
          dataArr.push({
            label: element.name,
            value: element.id,
          });
        }
        setPermissions(dataArr);
      }
      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        setServerError(true);
        networkErrorHandeller(error);
      }
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  /* Handle submit */
  const handleSubmit = async (data: any) => {
    try {
      setUpdate(true);
      const response = await NetworkServices.Admin.update(id || "", data);
      if (response && response.status === 200) {
        Toastify.Success(response.data.message);
      }

      setUpdate(false);
    } catch (error: any) {
      if (error) {
        setUpdate(false);
        networkErrorHandeller(error);
      }
    }
  };

  return (
    <div>
      <PageHeader
        title="Edit admin information."
        buttonIcon={<HiOutlineChevronLeft size={18} />}
        redirectPath="/admin"
      />

      {isLoading && !serverError && !data ? (
        <Card className="lg:p-5">
          <FormLoader />
        </Card>
      ) : null}

      {!isLoading && !data && serverError ? <NetworkError /> : null}
      {!isLoading && !data && !serverError ? (
        <NotFound message="Admin not found." />
      ) : null}

      {!isLoading && !serverError && data ? (
        <Card className="lg:p-5 !overflow-visible">
          <AdminForm
            formType="edit"
            data={data}
            loading={isUpdate}
            permissions={permissions}
            onSubmit={(data) => handleSubmit(data)}
          />
        </Card>
      ) : null}
    </div>
  );
};
