import { privateRequest } from "config/axios.config";
import { ICategory, ICategoryStore } from "types/category.types";

/* List of resources */
export const index = async () => {
  return await privateRequest.get(`api/admin/category`);
};

/* Store resource */
export const store = async (data: ICategory) => {
  return await privateRequest.post(`api/admin/category`, data);
};

/* Show specific resource */
export const show = async (id: string) => {
  return await privateRequest.get(`api/admin/category/${id}`);
};

/* Update specific resource */
export const update = async (id: string, data: ICategoryStore) => {
  return await privateRequest.put(`api/admin/category/${id}`, data);
};

/* Destroy specific resource */
export const destroy = async (id: string) => {
  return await privateRequest.delete(`api/admin/category/${id}`);
};
