import React from "react";

type PropsTypes = {
  text: String;
};

export const Badge: React.FC<PropsTypes> = (props: PropsTypes): JSX.Element => {
  return (
    <span className="inline-flex items-center rounded-md capitalize bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
      {props.text}
    </span>
  );
};
