import { PrimaryButton } from "components/button";
import { PasswordInput } from "components/input";
import { useForm } from "react-hook-form";

type PropsTypes = {
  loading: boolean;
  onSubmit: (data: any) => void;
};

export const SetPasswordForm: React.FC<PropsTypes> = (
  props: PropsTypes
): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  /* Handle form submit */
  const onSubmit = (data: any) => {
    if (data.password !== data.c_password) {
      return setError("c_password", {
        type: "custom",
        message: "The passwords do not match.",
      });
    }
    props.onSubmit(data);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* New password input control */}
        <div className="mb-6">
          <PasswordInput
            label="New password"
            name="password"
            type="text"
            placeholder="********"
            control={control}
            error={errors.password && errors.password.message}
            defaultvalue={""}
            rules={{ required: "New password is required" }}
          />
        </div>

        {/* Confirm password input control */}
        <div className="mb-6">
          <PasswordInput
            label="Confirm password"
            name="c_password"
            type="text"
            placeholder="********"
            control={control}
            error={errors.c_password && errors.c_password.message}
            defaultvalue={""}
            rules={{ required: "Confirm password is required" }}
          />
        </div>

        {/* Submit button */}
        <div className="text-center">
          <PrimaryButton
            type="submit"
            disabled={props.loading}
            className="!px-8"
          >
            {props.loading ? "Saving..." : "Save"}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};
