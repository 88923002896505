import React from "react";
import { PrimaryButton } from "components/button";
import { useForm, SubmitHandler } from "react-hook-form";
import { TextInput, MultiSelect } from "components/input";
import {
  IRPermissionShow,
  IRPermissionCreateUpdate,
  IRRole,
} from "types/permission.types";

type PropsTypes = {
  data: IRPermissionShow | null;
  roleList: IRRole[];
  loading: boolean;
  onSubmit: (data: any) => void;
};

export const PermissionForm: React.FC<PropsTypes> = (
  props: PropsTypes
): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IRPermissionCreateUpdate>();

  /* Handle form submit */
  const onSubmit: SubmitHandler<IRPermissionCreateUpdate> = (data) => {
    const rolesValues = data.roles.map((item: any) => item.value);
    const formData = {
      ...data,
      roles: rolesValues,
    };
    props.onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Name input control */}
      <div className="mb-6">
        <TextInput
          label="Permission name"
          name="name"
          type="text"
          placeholder="Enter name"
          control={control}
          error={errors.name && errors.name.message}
          defaultvalue={props.data?.name || ""}
          rules={{ required: "Permission name is required" }}
        />
      </div>

      {/* Role selection control */}
      <div className="mb-2">
        <MultiSelect
          label="Role"
          name="roles"
          control={control}
          error={errors.roles && errors.roles.message}
          options={props.roleList}
          isClearable={true}
          placeholder="Select roles"
          rules={{ required: "Roles is required" }}
          defaultvalue={props.data ? props.data.roles : []}
        />
      </div>

      {/* Submit button */}
      <div className="text-center mt-8 mb-4">
        <PrimaryButton type="submit" className="!px-7" disabled={props.loading}>
          {props.loading ? "Loading..." : "Submit"}
        </PrimaryButton>
      </div>
    </form>
  );
};
