import { NotFound } from "components/404";
import { NetworkError } from "components/501";
import { Card } from "components/card";
import { ProfileLoader } from "components/ghostloader/profile.ghostloader";
import { PageHeader } from "components/page-header";
import { NetworkServices } from "network";
import { useCallback, useEffect, useState } from "react";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { useParams } from "react-router-dom";
import { IPayment } from "types/payment.types";
import { networkErrorHandeller } from "utils/helper";
import { BsDot } from "react-icons/bs";
import {
  AiOutlineWhatsApp,
  AiOutlineMail,
  AiOutlineUser,
} from "react-icons/ai";
import { WhiteButton } from "components/button";
import { CustomModal } from "components/modal";
import { PaymentStatusForm } from "components/form/payment-status.form";
import { Toastify } from "components/toastify";

export const PaymentShow: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const [data, setData] = useState<IPayment | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [serverError, setServerError] = useState<boolean>(false);
  const [openPaymentStatusModal, setOpenPaymentStatusModal] =
    useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);

  /* Fetch data */
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await NetworkServices.Payment.show(id || "");
      if (response && response.status === 200) {
        setData(response?.data?.data);
      }
      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        setServerError(true);
        networkErrorHandeller(error);
      }
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  /** handle payment status submission */
  const handlePaymentStatusSubmit = async (data: any) => {
    try {
      setUpdateLoading(true);
      const response = await NetworkServices.Payment.update(id || "", data);
      if (response && response.status === 200) {
        setData(null);
        fetchData();
        Toastify.Success(response?.data?.message);
      }

      setUpdateLoading(false);
      setOpenPaymentStatusModal(false);
    } catch (error: any) {
      setUpdateLoading(false);
      networkErrorHandeller(error);
    }
  };

  return (
    <div>
      <PageHeader
        title="Payment information"
        buttonIcon={<HiOutlineChevronLeft size={18} />}
        redirectPath="/payment"
      />

      {isLoading && !serverError && !data ? (
        <Card className="lg:p-5">
          <ProfileLoader />
        </Card>
      ) : null}
      {!isLoading && !data && serverError ? <NetworkError /> : null}
      {!isLoading && !data && !serverError ? (
        <NotFound message="Payment information not found." />
      ) : null}

      {!isLoading && !serverError && data ? (
        <Card className="!px-0 !py-6">
          {/* User details */}
          <div className="pb-6 px-4 border-b">
            <p className="text-sm mb-3 inline-flex text-primary">
              <BsDot size={20} />
              User details
            </p>

            <div className="pl-5">
              <p className="text-sm inline-flex gap-2 text-muted">
                <AiOutlineUser size={18} />
                {data.user.name}
              </p>
              <br />
              <p className="text-muted text-sm inline-flex gap-2">
                <AiOutlineMail size={18} />
                {data.user.email}
              </p>
              <br />
              <p className="text-muted text-sm inline-flex gap-2">
                <AiOutlineWhatsApp size={18} />
                {data.user.phone}
              </p>
            </div>
          </div>

          {/* Payment details */}
          <div className="px-4 py-6 border-b">
            <p className="text-sm mb-3 inline-flex text-primary">
              <BsDot size={20} />
              Payment details
            </p>

            <div className="flex gap-2 pl-5">
              <div className="flex-none w-28">
                <p className="text-sm mb-1 text-muted">Pay by</p>
                <p className="text-sm mb-1 text-muted">Pay number</p>
                <p className="text-sm mb-1 text-muted">TRXID</p>
                <p className="text-sm mb-1 text-muted">Amount</p>
                <p className="text-sm text-muted">Status</p>
              </div>
              <div>
                <p className="text-sm mb-1 text-muted">
                  : {data.payment.payment_method}
                </p>
                <p className="text-sm mb-1 text-muted">
                  : {data.payment.payment_number}
                </p>
                <p className="text-sm mb-1 text-muted">
                  : {data.payment.trxid}
                </p>
                <p className="text-sm mb-1 text-muted">
                  :{data.payment.amount}tk.
                </p>
                <p
                  className={`${
                    data.payment.payment_status === "Pending"
                      ? "text-sm text-yellow-500"
                      : "text-sm text-green-500"
                  }`}
                >
                  : {data.payment.payment_status}
                </p>
              </div>
            </div>

            <div className="pt-6">
              <WhiteButton
                type="button"
                className="!text-primary"
                onClick={() => setOpenPaymentStatusModal(true)}
              >
                Update Payment Status
              </WhiteButton>
            </div>
          </div>

          {/* Course details */}
          <div className="px-4 pt-6">
            <p className="text-sm mb-4 inline-flex text-primary">
              <BsDot size={20} />
              Course details
            </p>

            <div className="sm:flex gap-3 pl-5">
              <div>
                <img
                  src={data.course.banner}
                  alt="Course banner"
                  className="w-full sm:w-[120px] h-[200px] sm:h-[70px]"
                />
              </div>
              <div className="pt-3 sm:pt-0">
                <p className="text-sm mb-1 font-medium text-muted">
                  {data.course.name}
                </p>
                <p className="text-sm font-medium text-muted">
                  Course Fee:{" "}
                  <span className="text-black">
                    {data.course.course_fee} tk.
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* Payment status change modal */}
          <CustomModal
            show={openPaymentStatusModal}
            onHide={() => {
              updateLoading
                ? setOpenPaymentStatusModal(true)
                : setOpenPaymentStatusModal(false);
            }}
            title="Change payment status."
          >
            <PaymentStatusForm
              loading={updateLoading}
              onSubmit={handlePaymentStatusSubmit}
            />
          </CustomModal>
        </Card>
      ) : null}
    </div>
  );
};
