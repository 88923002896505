import { Navigate } from "react-router-dom";
import { IAppRoute } from "types/routes";

/* Layouts */
import { DashbaordLayout } from "layouts/dashboard";

/* Profile pages */
import { ProfileIndex } from "pages/profile";
import { ChangePassword } from "pages/profile/change-password";

/* Dashboard pages */
import { Dashboard } from "pages/dashboard";

/* Heading banner pages */
import { HeadingBannerIndex } from "pages/heading-banner";
import { HeadingBannerCreate } from "pages/heading-banner/create";

/* Training banner pages */
import { TrainingBannerIndex } from "pages/training-banner";
import { TrainingBannerCreate } from "pages/training-banner/create";

/* Admin pages */
import { AdminIndex } from "pages/admin";
import { AdminStore } from "pages/admin/store";
import { AdminEdit } from "pages/admin/edit";

/* Permission pages */
import { PermissionIndex } from "pages/permission";
import { PermissionCreate } from "pages/permission/create";
import { PermissionEdit } from "pages/permission/edit";

/* User pages */
import { UserIndex } from "pages/user";
import { UserShow } from "pages/user/show";

/* Category pages */
import { CategoryIndex } from "pages/category";
import { CategoryCreate } from "pages/category/create";
import { CategoryEdit } from "pages/category/edit";

/* Course pages */
import { CourseIndex } from "pages/course";
import { CourseCreate } from "pages/course/create";
import { CourseEdit } from "pages/course/edit";
import { CourseShow } from "pages/course/show";

/* Payment pages */
import { PaymentIndex } from "pages/payment";
import { PaymentShow } from "pages/payment/show";

/* Blog pages */
import { BlogIndex } from "pages/blog";
import { BlogCreate } from "pages/blog/create";
import { BlogEdit } from "pages/blog/edit";
import { BlogShow } from "pages/blog/show";

/** Team pages */
import { TeamIndex } from "pages/team";
import { TeamStore } from "pages/team/store";
import { TeamEdit } from "pages/team/edit";

/* Subscription pages */
import { SubscriptionIndex } from "pages/subscription";

import { getToken, getPermissionFromToken } from "utils/helper";

const appRoutes: IAppRoute[] = [
  /** Profile routes */
  {
    path: "profile",
    in_drawer: false,
    element: <DashbaordLayout />,
    multi_menu: false,
    children: [
      {
        path: "*",
        in_drawer: false,
        multi_menu: false,
        element: <Navigate to="/404" />,
      },
      {
        path: "",
        in_drawer: false,
        multi_menu: false,
        element: <ProfileIndex />,
      },
      {
        path: "change-password",
        in_drawer: false,
        multi_menu: false,
        element: <ChangePassword />,
      },
    ],
  },
  /** Dashboard routes */
  {
    path: "dashboard",
    in_drawer: true,
    element: <DashbaordLayout />,
    multi_menu: false,
    children: [
      {
        path: "*",
        in_drawer: false,
        multi_menu: false,
        element: <Navigate to="/404" />,
      },
      { path: "", in_drawer: false, multi_menu: false, element: <Dashboard /> },
    ],
  },
  /** Heading banner routes */
  {
    path: "banner",
    in_drawer: true,
    element: <DashbaordLayout />,
    multi_menu: false,
    children: [
      {
        path: "*",
        in_drawer: false,
        multi_menu: false,
        element: <Navigate to="/404" />,
      },
      {
        path: "",
        in_drawer: false,
        multi_menu: false,
        element: <HeadingBannerIndex />,
      },
      {
        path: "create",
        in_drawer: false,
        multi_menu: false,
        element: <HeadingBannerCreate />,
      },
    ],
  },
  /** Training banner routes */
  {
    path: "training",
    in_drawer: true,
    element: <DashbaordLayout />,
    multi_menu: false,
    children: [
      {
        path: "*",
        in_drawer: false,
        multi_menu: false,
        element: <Navigate to="/404" />,
      },
      {
        path: "",
        in_drawer: false,
        multi_menu: false,
        element: <TrainingBannerIndex />,
      },
      {
        path: "create",
        in_drawer: false,
        multi_menu: false,
        element: <TrainingBannerCreate />,
      },
    ],
  },
  /** Admin routes */
  {
    path: "admin",
    in_drawer: true,
    element: <DashbaordLayout />,
    multi_menu: false,
    children: [
      {
        path: "*",
        in_drawer: false,
        multi_menu: false,
        element: <Navigate to="/404" />,
      },
      {
        path: "",
        in_drawer: false,
        multi_menu: false,
        element: <AdminIndex />,
      },
      {
        path: "create",
        in_drawer: false,
        multi_menu: false,
        element: <AdminStore />,
      },
      {
        path: "edit/:id",
        in_drawer: false,
        multi_menu: false,
        element: <AdminEdit />,
      },
    ],
  },
  /** Permission routes */
  {
    path: "permission",
    in_drawer: true,
    element: <DashbaordLayout />,
    multi_menu: false,
    children: [
      {
        path: "*",
        in_drawer: false,
        multi_menu: false,
        element: <Navigate to="/404" />,
      },
      {
        path: "",
        in_drawer: false,
        multi_menu: false,
        element: <PermissionIndex />,
      },
      {
        path: "create",
        in_drawer: false,
        multi_menu: false,
        element: <PermissionCreate />,
      },
      {
        path: "edit/:id",
        in_drawer: false,
        multi_menu: false,
        element: <PermissionEdit />,
      },
    ],
  },
  /** User routes */
  {
    path: "user",
    in_drawer: true,
    element: <DashbaordLayout />,
    multi_menu: false,
    children: [
      {
        path: "*",
        in_drawer: false,
        multi_menu: false,
        element: <Navigate to="/404" />,
      },
      {
        path: "",
        in_drawer: false,
        multi_menu: false,
        element: <UserIndex />,
      },
      {
        path: "show/:id",
        in_drawer: false,
        multi_menu: false,
        element: <UserShow />,
      },
    ],
  },
  /** Category routes */
  {
    path: "category",
    in_drawer: true,
    element: <DashbaordLayout />,
    multi_menu: false,
    children: [
      {
        path: "*",
        in_drawer: false,
        multi_menu: false,
        element: <Navigate to="/404" />,
      },
      {
        path: "",
        in_drawer: false,
        multi_menu: false,
        element: <CategoryIndex />,
      },
      {
        path: "create",
        in_drawer: false,
        multi_menu: false,
        element: <CategoryCreate />,
      },
      {
        path: "edit/:id",
        in_drawer: false,
        multi_menu: false,
        element: <CategoryEdit />,
      },
    ],
  },
  /** Course routes */
  {
    path: "course",
    in_drawer: true,
    element: <DashbaordLayout />,
    multi_menu: false,
    children: [
      {
        path: "*",
        in_drawer: false,
        multi_menu: false,
        element: <Navigate to="/404" />,
      },
      {
        path: "",
        in_drawer: false,
        multi_menu: false,
        element: <CourseIndex />,
      },
      {
        path: "create",
        in_drawer: false,
        multi_menu: false,
        element: <CourseCreate />,
      },
      {
        path: "edit/:id",
        in_drawer: false,
        multi_menu: false,
        element: <CourseEdit />,
      },
      {
        path: "show/:id",
        in_drawer: false,
        multi_menu: false,
        element: <CourseShow />,
      },
    ],
  },
  /** Payment routes */
  {
    path: "payment",
    in_drawer: true,
    element: <DashbaordLayout />,
    multi_menu: false,
    children: [
      {
        path: "*",
        in_drawer: false,
        multi_menu: false,
        element: <Navigate to="/404" />,
      },
      {
        path: "",
        in_drawer: false,
        multi_menu: false,
        element: <PaymentIndex />,
      },
      {
        path: "show/:id",
        in_drawer: false,
        multi_menu: false,
        element: <PaymentShow />,
      },
    ],
  },
  /** Blog routes */
  {
    path: "blog",
    in_drawer: true,
    element: <DashbaordLayout />,
    multi_menu: false,
    children: [
      {
        path: "*",
        in_drawer: false,
        multi_menu: false,
        element: <Navigate to="/404" />,
      },
      {
        path: "",
        in_drawer: false,
        multi_menu: false,
        element: <BlogIndex />,
      },
      {
        path: "create",
        in_drawer: false,
        multi_menu: false,
        element: <BlogCreate />,
      },
      {
        path: "edit/:id",
        in_drawer: false,
        multi_menu: false,
        element: <BlogEdit />,
      },
      {
        path: "show/:id",
        in_drawer: false,
        multi_menu: false,
        element: <BlogShow />,
      },
    ],
  },
  /** Team routes */
  {
    path: "team",
    in_drawer: true,
    element: <DashbaordLayout />,
    multi_menu: false,
    children: [
      {
        path: "*",
        in_drawer: false,
        multi_menu: false,
        element: <Navigate to="/404" />,
      },
      {
        path: "",
        in_drawer: false,
        multi_menu: false,
        element: <TeamIndex />,
      },
      {
        path: "create",
        in_drawer: false,
        multi_menu: false,
        element: <TeamStore />,
      },
      {
        path: "edit/:id",
        in_drawer: false,
        multi_menu: false,
        element: <TeamEdit />,
      },
      {
        path: "show/:id",
        in_drawer: false,
        multi_menu: false,
        element: <BlogShow />,
      },
    ],
  },
  /** Subscription routes */
  {
    path: "subscription",
    in_drawer: true,
    element: <DashbaordLayout />,
    multi_menu: false,
    children: [
      {
        path: "*",
        in_drawer: false,
        multi_menu: false,
        element: <Navigate to="/404" />,
      },
      {
        path: "",
        in_drawer: false,
        multi_menu: false,
        element: <SubscriptionIndex />,
      },
    ],
  },
];

/* Generate permitted routes */
export const permittedRoutes = () => {
  const token = getToken();
  if (token) {
    const permissions = getPermissionFromToken(token);
    if (permissions[0] === "all") {
      return appRoutes;
    } else {
      return appRoutes.filter(({ path: routeName }) =>
        permissions.some((x) => x === routeName)
      );
    }
  }

  return [];
};
