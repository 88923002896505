import { Navigate, useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { MainLayout } from "layouts/main";
import { Login } from "pages/login";
import { Reset } from "pages/reset";
import { OtpVerification } from "pages/reset/otp-verification";
import { SetPassword } from "pages/reset/set-password";
import { FourOFour } from "pages/404";
import { permittedRoutes } from "routes";

const App: React.FC = (): JSX.Element => {
  const mainRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "*", element: <Navigate to="/404" /> },
      { path: "/", element: <Login /> },
      { path: "reset", element: <Reset /> },
      { path: "otp-verification/:employeeid", element: <OtpVerification /> },
      { path: "set-password/:employeeid/:otp", element: <SetPassword /> },
      { path: "404", element: <FourOFour /> },
    ],
  };

  const routing = useRoutes([mainRoutes, ...permittedRoutes()]);
  return (
    <>
      {routing}
      <ToastContainer />
    </>
  );
};

export default App;
