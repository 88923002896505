import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card } from "components/card";
import { DataTable } from "components/table";
import { IUser } from "types/user.types";
import { IRDataColumns } from "types/datatable.types";
import { NetworkServices } from "network";
import { networkErrorHandeller } from "utils/helper";
import { WhiteCircleButton } from "components/button";
import { MdRemoveRedEye } from "react-icons/md";
import { PageHeader } from "components/page-header";

export const UserIndex: React.FC = (): JSX.Element => {
  const [data, setData] = useState<IUser[] | []>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [totalRows, setTotalRows] = useState<number>(0);

  /* Fetch data */
  const fetchData = useCallback(async (page: number) => {
    try {
      setLoading(true);
      const response = await NetworkServices.User.index({
        page,
      });
      if (response && response.status === 200) {
        setData(response?.data?.data?.data);
        setTotalRows(response?.data?.data?.total);
      }
      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        networkErrorHandeller(error);
      }
    }
  }, []);

  useEffect(() => {
    fetchData(1);
  }, [fetchData]);

  /* handle paginate page change */
  const handlePageChange = (page: number) => fetchData(page);

  /* data columns */
  const columns: IRDataColumns[] = [
    {
      name: "ID",
      maxWidth: "50px",
      minWidth: "50px",
      selector: (row) => row.id,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Action",
      maxWidth: "60px",
      cell: (row) => (
        <Link to={`/user/show/${row.id}`}>
          <WhiteCircleButton type="button">
            <MdRemoveRedEye size={18} />
          </WhiteCircleButton>
        </Link>
      ),
    },
  ];

  return (
    <div>
      <PageHeader title="User list" redirectPath="/user" buttonIcon={null} />

      <Card>
        <DataTable
          data={data}
          columns={columns}
          loading={isLoading}
          pagination={true}
          paginationServer={true}
          totalRows={totalRows}
          handlePageChange={handlePageChange}
          noDataMessage="No data found."
        />
      </Card>
    </div>
  );
};
