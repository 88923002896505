import { Card } from "components/card";
import { arrayFromNumber } from "utils/helper";

export const DashboardLoader: React.FC = (): JSX.Element => {
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-4 md:gap-5 2xl:gap-5">
        {arrayFromNumber(4).map((i: number) => (
          <Card className="!p-5" key={i}>
            <div className="animate-pulse mb-5 ml-auto w-32 rounded-md h-[22px] bg-slate-200" />
            <div className="ml-auto h-10 w-[200px] animate-pulse rounded-md bg-slate-200" />
          </Card>
        ))}
      </div>
    </>
  );
};
