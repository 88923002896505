import { arrayFromNumber } from "utils/helper";

export const FormLoader: React.FC = (): JSX.Element => {
  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        {arrayFromNumber(4).map((i: number) => (
          <div key={i} className="animate-pulse mb-2">
            <div className="h-3 w-[200px] bg-slate-200 rounded-full mb-2" />
            <div className="h-[50px] bg-slate-200 rounded-md" />
          </div>
        ))}
      </div>
      <div className="animate-pulse">
        <div className="w-[120px] h-[47px] rounded-md ml-auto bg-slate-200" />
      </div>
    </>
  );
};
