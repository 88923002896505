import { useForm } from "react-hook-form";
import { PrimaryButton } from "components/button";
import { FileUploader } from "components/file-uploader";
import { TextAreaInput, TextInput } from "components/input";

type PropsTypes = {
  loading: boolean;
  onSubmit: (data: any) => void;
};

export const TrainingBannerForm: React.FC<PropsTypes> = (
  props: PropsTypes
): JSX.Element => {
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();

  /* Handle set value */
  const handleSetValue = ({ key, value }: { key: string; value: string }) => {
    setValue(`${key}`, value);
    clearErrors(key);
  };

  /* Handle form submit */
  const onSubmit = (data: any) => {
    if (!data.image) {
      return setError("image", {
        type: "custom",
        message: "Banner image is required.",
      });
    }

    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("image", data.image);

    props.onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 gap-6 sm:gap-8 sm:mb-6">
        {/* Title input control */}
        <div className="mb-6 lg:mb-2">
          <TextInput
            label="Title"
            name="title"
            type="text"
            placeholder="Enter title"
            control={control}
            error={errors.title && errors.title.message}
            defaultvalue={""}
            rules={{ required: "Title is required" }}
          />
        </div>

        {/* Description input control */}
        <div className="mb-6 lg:mb-2">
          <TextAreaInput
            label="Description"
            name="description"
            type="text"
            placeholder="Enter description"
            control={control}
            error={errors.description && errors.description.message}
            defaultvalue={""}
            rules={{ required: "Description is required." }}
          />
        </div>
      </div>

      {/*Banner image */}
      <div className="w-full sm:w-72 mx-auto">
        <p className="text-sm mb-2 text-muted">Banner size (1920X550)</p>
        <FileUploader
          isLoading={false}
          defaultValue={null}
          error={errors.image && errors.image.message}
          onUploded={(data) => handleSetValue({ key: "image", value: data })}
        />
      </div>

      {/* Submit button */}
      <div className="text-center pt-6">
        <PrimaryButton type="submit" disabled={props.loading}>
          {props.loading ? "Loading..." : "Submit"}
        </PrimaryButton>
      </div>
    </form>
  );
};
