import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card } from "components/card";
import { DataTable } from "components/table";
import { IRDataColumns } from "types/datatable.types";
import { NetworkServices } from "network";
import { networkErrorHandeller } from "utils/helper";
import { WhiteCircleButton } from "components/button";
import { MdRemoveRedEye } from "react-icons/md";
import { PageHeader } from "components/page-header";
import { IPaymentList } from "types/payment.types";

export const PaymentIndex: React.FC = (): JSX.Element => {
  const [data, setData] = useState<IPaymentList[] | []>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [totalRows, setTotalRows] = useState<number>(0);

  /* Fetch data */
  const fetchData = useCallback(async (page: number) => {
    try {
      setLoading(true);
      const response = await NetworkServices.Payment.index({
        page,
      });
      if (response && response.status === 200) {
        setData(response?.data?.data?.data);
        setTotalRows(response?.data?.data?.total);
      }
      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        networkErrorHandeller(error);
      }
    }
  }, []);

  useEffect(() => {
    fetchData(1);
  }, [fetchData]);

  /* handle paginate page change */
  const handlePageChange = (page: number) => fetchData(page);

  /* data columns */
  const columns: IRDataColumns[] = [
    {
      name: "ID",
      maxWidth: "50px",
      minWidth: "50px",
      selector: (row) => row.id,
    },
    {
      name: "TRXID",
      maxWidth: "150px",
      minWidth: "150px",
      selector: (row) => row.trxid,
    },
    {
      name: "Paid amount",
      maxWidth: "150px",
      minWidth: "150px",
      cell: (row) => <p>{row.amount}tk.</p>,
    },
    {
      name: "Course",
      minWidth: "250px",
      cell: (row) => (
        <div>
          <p className="mb-1">
            <b>Course: </b>
            {row.course_name}
          </p>
          <p>
            <b>Course Fee: </b>
            {row.course_fee} tk.
          </p>
        </div>
      ),
    },
    {
      name: "Payment Status",
      maxWidth: "140px",
      minWidth: "140px",
      sortable: true,
      cell: (row) => (
        <p
          className={`${
            row.payment_status === "Pending"
              ? "text-yellow-500"
              : "text-green-500"
          }`}
        >
          {row.payment_status}
        </p>
      ),
    },
    {
      name: "Payment Method",
      maxWidth: "130px",
      minWidth: "130px",
      selector: (row) => row.payment_method,
    },
    {
      name: "Number",
      maxWidth: "130px",
      minWidth: "130px",
      selector: (row) => row.payment_number,
    },
    {
      name: "Action",
      maxWidth: "70px",
      minWidth: "70px",
      cell: (row) => (
        <Link to={`/payment/show/${row.id}`}>
          <WhiteCircleButton type="button">
            <MdRemoveRedEye size={18} />
          </WhiteCircleButton>
        </Link>
      ),
    },
  ];

  return (
    <div>
      <PageHeader title="Payment list" redirectPath="" buttonIcon={null} />

      <Card>
        <DataTable
          data={data}
          columns={columns}
          loading={isLoading}
          pagination={true}
          paginationServer={true}
          totalRows={totalRows}
          handlePageChange={handlePageChange}
          noDataMessage="No payments found."
        />
      </Card>
    </div>
  );
};
