export const CourseLoader: React.FC = (): JSX.Element => {
  return (
    <div className="animate-pulse">
      <div className="w-3/4 md:w-1/2 lg:w-1/4 h-4 rounded-lg mb-3 bg-slate-200" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="w-full h-[250px] rounded-lg bg-slate-200" />
        <div className="w-full h-[250px]">
          <div className="grid grid-cols-2 gap-4">
            <div className="w-full h-[117px] rounded-lg bg-slate-200" />
            <div className="w-full h-[117px] rounded-lg bg-slate-200" />
            <div className="w-full h-[117px] rounded-lg bg-slate-200" />
            <div className="w-full h-[117px] rounded-lg bg-slate-200" />
          </div>
        </div>
      </div>
    </div>
  );
};
