import { Fragment } from "react";
import { Images } from "utils/images";
import { Menu, Transition } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import { getToken, removeToken, getPermissionFromToken } from "utils/helper";

export const ProfileDropdown: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const token = getToken();
  const permissions = getPermissionFromToken(token || "");

  /** handle permission check */
  const permittedForProfile = () => {
    if (permissions && permissions.length) {
      if (permissions[0] === "all") {
        return true;
      }

      if (permissions.indexOf("profile") === 1) {
        return true;
      }
    }

    return false;
  };

  /* Handle logout */
  const handleLogout = () => {
    removeToken();
    navigate("/");
  };

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div className="inline-flex">
          <Menu.Button className="rounded-full mb-0">
            <img
              src={Images.Avatar}
              alt="Avatar"
              className="rounded-full w-[43px] h-[43px]"
            />
          </Menu.Button>
          <div className="pt-3 pl-2">
            <p className="text-sm font-medium text-muted">Welcome</p>
          </div>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 w-[200px] mt-2 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg">
            <div className="p-3">
              {permittedForProfile() ? (
                <Menu.Item>
                  <Link to="/profile">
                    <button className="px-3 py-2 w-full font-medium text-[15px] rounded-md text-indigo-500 hover:bg-indigo-100 transition ease-in-out delay-50 text-left">
                      Profile
                    </button>
                  </Link>
                </Menu.Item>
              ) : null}

              {permittedForProfile() ? (
                <Menu.Item>
                  <Link to="/profile/change-password">
                    <button className="px-3 py-2 w-full font-medium text-[15px] rounded-md text-indigo-500 hover:bg-indigo-100 transition ease-in-out delay-50 text-left">
                      Change password
                    </button>
                  </Link>
                </Menu.Item>
              ) : null}
              <Menu.Item>
                <button
                  onClick={handleLogout}
                  className="px-3 py-2 w-full font-medium text-[15px] rounded-md text-indigo-500 hover:bg-indigo-100 transition ease-in-out delay-50 text-left"
                >
                  Logout
                </button>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};
