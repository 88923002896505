import { useForm } from "react-hook-form";
import { PrimaryButton } from "components/button";
import { FileUploader } from "components/file-uploader";

type PropsTypes = {
  loading: boolean;
  onSubmit: (data: any) => void;
};

export const HeadingBannerForm: React.FC<PropsTypes> = (
  props: PropsTypes
): JSX.Element => {
  const {
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();

  /* Handle set value */
  const handleSetValue = ({ key, value }: { key: string; value: string }) => {
    setValue(`${key}`, value);
    clearErrors(key);
  };

  /* Handle form submit */
  const onSubmit = (data: any) => {
    if (!data.image) {
      return setError("image", {
        type: "custom",
        message: "Banner image is required.",
      });
    }

    const formData = new FormData();
    formData.append("image", data.image);

    props.onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Banner image */}
      <div className="w-full sm:w-72 mx-auto">
        <p className="text-sm mb-2 text-muted">Banner size (1920X550)</p>
        <FileUploader
          isLoading={false}
          defaultValue={null}
          error={errors.image && errors.image.message}
          onUploded={(data) => handleSetValue({ key: "image", value: data })}
        />
      </div>

      {/* Submit button */}
      <div className="text-center pt-6">
        <PrimaryButton type="submit" disabled={props.loading}>
          {props.loading ? "Loading..." : "Submit"}
        </PrimaryButton>
      </div>
    </form>
  );
};
