import React from "react";
import { NetworkServices } from "network";
import { ICourse } from "types/course.types";
import { PrimaryButton } from "components/button";
import {
  arrayFromNumber,
  networkErrorHandeller,
  timeparse,
} from "utils/helper";
import { useCallback, useEffect, useState } from "react";
import { FileUploader } from "components/file-uploader";
import {
  TextInput,
  SingleSelect,
  DateInput,
  TimeInput,
} from "components/input";
import { useForm } from "react-hook-form";
import { MdStar } from "react-icons/md";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

type PropsTypes = {
  loading: boolean;
  data: ICourse | null;
  formType: "create" | "edit";
  onSubmit: (data: any) => void;
};

interface IOptions {
  label: string;
  value: number;
}

type ISchedule = {
  id?: number;
  date: Date;
  time: string;
  class_link: string;
  errors: {
    date: null | string;
    time: null | string;
    class_link: null | string;
  };
};

const scheduleItem = {
  date: new Date(),
  time: timeparse(new Date()),
  class_link: "",
  errors: {
    date: null,
    time: null,
    class_link: null,
  },
};

export const CourseForm: React.FC<PropsTypes> = (
  props: PropsTypes
): JSX.Element => {
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    setError,
    formState: { errors },
  } = useForm();
  const watchTotalClass = watch("total_class");
  const watchDescription = watch("description");
  const [categoryList, setCategoryList] = useState<IOptions[] | []>([]);
  const [scheduleList, setScheduleList] = useState<ISchedule[] | []>([]);

  /* get category list data */
  const getCategoryList = useCallback(async () => {
    try {
      const response = await NetworkServices.Category.index();
      if (response && response.status === 200) {
        const items = [];
        if (response.data && response.data.data && response.data.data.length) {
          for (let i = 0; i < response.data.data.length; i++) {
            items.push({
              label: response.data.data[i].name,
              value: response.data.data[i].id,
            });
          }
        }

        setCategoryList(items);
      }
    } catch (error) {
      if (error) {
        networkErrorHandeller(error);
      }
    }
  }, []);

  useEffect(() => {
    getCategoryList();
  }, [getCategoryList]);

  useEffect(() => {
    /** handelling for edit */
    if (props.data && props.formType === "edit") {
      const scheduleArr = [];
      const masterData = props.data.schedules;
      for (let i = 0; i < masterData.length; i++) {
        scheduleArr.push({
          id: masterData[i].id,
          date: new Date(masterData[i].date),
          time: masterData[i].time,
          class_link: masterData[i].class_link,
          errors: {
            date: null,
            time: null,
            class_link: null,
          },
        });
      }

      setScheduleList(scheduleArr);
      setValue("description", props.data.description);
    }

    /** handelling for create */
    if (props.formType === "create" && watchTotalClass) {
      const items = [];
      const arr = arrayFromNumber(watchTotalClass);

      for (let i = 0; i < arr.length; i++) {
        items.push({ ...scheduleItem });
      }

      setScheduleList(items);
    }
  }, [watchTotalClass, props.data, props.formType, setValue]);

  /* Handle set value */
  const handleSetValue = ({ key, value }: { key: string; value: string }) => {
    setValue(`${key}`, value);
    clearErrors(key);
  };

  /* Handle form submit */
  const onSubmit = (data: any) => {
    if (!data.description) {
      return setError("description", {
        type: "custom",
        message: "Course description is required.",
      });
    }

    if (props.formType === "create" && !data.banner) {
      setError("banner", {
        type: "custom",
        message: "Banner is required.",
      });

      return;
    }

    /** Submit create data */
    if (props.formType === "create") {
      const formData = new FormData();
      formData.append("category", data.category.value);
      formData.append("name", data.name);
      formData.append("instructor", data.instructor);
      formData.append("course_fee", data.course_fee);
      formData.append("total_class", data.total_class);
      formData.append("description", data.description);
      formData.append("banner", data.banner);

      const arr = arrayFromNumber(watchTotalClass);
      for (let i = 0; i < arr.length; i++) {
        formData.append(`schedules[${i}][date]`, data.schedules[i].date);
        formData.append(`schedules[${i}][time]`, data.schedules[i].time);
        formData.append(
          `schedules[${i}][class_link]`,
          data.schedules[i].class_link
        );
      }

      props.onSubmit(formData);
    }

    /** Submit edit data */
    if (props.formType === "edit") {
      const formData = {
        ...data,
        category: data.category.value,
      };

      props.onSubmit(formData);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Category select control */}
        <SingleSelect
          label="Category"
          name="category"
          control={control}
          error={errors.category && errors.category.message}
          options={categoryList}
          isClearable={true}
          placeholder="Select category"
          rules={{ required: "Category is required" }}
          defaultvalue={
            props.data
              ? {
                  value: props.data.category.id,
                  label: props.data.category.name,
                }
              : null
          }
        />

        {/* Name input control */}
        <TextInput
          label="Course name"
          name="name"
          type="text"
          placeholder="Enter course name"
          control={control}
          error={errors.name && errors.name.message}
          defaultvalue={props.data?.name || ""}
          rules={{ required: "Course name is required" }}
        />

        {/* Instructor input control */}
        <TextInput
          label="Instructor name"
          name="instructor"
          type="text"
          placeholder="Enter instructor name"
          control={control}
          error={errors.instructor && errors.instructor.message}
          defaultvalue={props.data?.instructor || ""}
          rules={{ required: "Instructor name is required" }}
        />

        {/* Course fee input control */}
        <TextInput
          label="Course fee"
          name="course_fee"
          type="number"
          placeholder="Enter course fee"
          control={control}
          error={errors.course_fee && errors.course_fee.message}
          defaultvalue={props.data?.course_fee || ""}
          rules={{ required: "Course fee is required" }}
        />
      </div>

      {/* Total class input control */}
      <div className="my-6">
        <TextInput
          label="Total class"
          name="total_class"
          type="number"
          placeholder="Enter total class quantity"
          control={control}
          error={errors.total_class && errors.total_class.message}
          defaultvalue={props.data?.total_class || 1}
          rules={{ required: "Total class number is required" }}
          disabled={props.formType === "edit"}
        />
      </div>

      {/* Description input control */}
      <div className="mb-6">
        {errors.description && errors.description.message ? (
          <p className="text-sm mb-1 text-danger">
            Blog description is required.
          </p>
        ) : (
          <p className="text-sm mb-1 text-gray-500">Blog description</p>
        )}
        <ReactQuill
          theme="snow"
          placeholder="Write course description..."
          value={watchDescription || ""}
          onChange={(data) =>
            handleSetValue({ key: "description", value: data })
          }
        />
      </div>

      {/* Class schedule dynamic input start */}
      <div className="p-6 my-6 rounded-lg bg-primary-transparent">
        <p className="text-md font-medium mb-6 text-primary">
          Make class schedule
        </p>
        {scheduleList.map((item, index) => (
          <div key={index}>
            <p className="text-sm font-bold inline-flex gap-2 mb-2 text-primary">
              <MdStar size={18} /> Class schedule {index + 1}
            </p>

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-8">
              {/* Id input */}
              {item.id ? (
                <div className="hidden">
                  <TextInput
                    label=""
                    name={`schedules.${index}.id`}
                    type="text"
                    placeholder=""
                    control={control}
                    error={false}
                    defaultvalue={item.id.toString()}
                    rules={{ required: true }}
                  />
                </div>
              ) : null}

              {/* Date input */}
              <DateInput
                label="Date"
                name={`schedules.${index}.date`}
                placeholder="Select date"
                control={control}
                error={item.errors.date}
                defaultvalue={item.date.toString() || ""}
                rules={{ required: true }}
              />

              {/* Time input */}
              <TimeInput
                label="Time"
                name={`schedules.${index}.time`}
                placeholder="Select time"
                control={control}
                error={item.errors.time}
                defaultvalue={item.time}
                rules={{ required: true }}
              />

              {/* Class link */}
              <TextInput
                label="Class link"
                name={`schedules.${index}.class_link`}
                type="text"
                placeholder="https://www.meet.google.com/xyz"
                control={control}
                error={item.errors.class_link}
                defaultvalue={item.class_link}
                rules={{ required: true }}
              />
            </div>
          </div>
        ))}
      </div>
      {/* Class schedule dynamic input end */}

      {/* Banner input */}
      {props.formType === "create" ? (
        <div className="mb-6">
          <div className="w-full sm:w-72">
            <p className="text-sm mb-2 text-muted">Banner size (1920X550)</p>
            <FileUploader
              isLoading={false}
              defaultValue={props.data?.banner || null}
              error={errors.banner && errors.banner.message}
              onUploded={(data) =>
                handleSetValue({ key: "banner", value: data })
              }
            />
          </div>
        </div>
      ) : null}

      {/* Submit button */}
      <div className="text-center mt-8 mb-4">
        <PrimaryButton type="submit" className="!px-7" disabled={props.loading}>
          {props.loading ? "Loading..." : "Submit"}
        </PrimaryButton>
      </div>
    </form>
  );
};
