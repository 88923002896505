import { useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { VscCloudUpload } from "react-icons/vsc";

type PropsTypes = {
  error: any;
  isLoading: boolean;
  defaultValue: string | null;
  onUploded: (data: any) => void;
};

export const FileUploader: React.FC<PropsTypes> = (
  props: PropsTypes
): JSX.Element => {
  const [data, setData] = useState<string | null>(props.defaultValue);

  /* Handle upload */
  const handleUpload = async (event: any) => {
    const fileInfo = event.target.files[0];
    setData(URL.createObjectURL(fileInfo));
    props.onUploded(fileInfo);
  };

  /* Handle clear */
  const handleClear = () => setData(null);

  return (
    <div
      className={`w-full border ${
        props.error ? "border-red-500" : ""
      } rounded-lg overflow-hidden`}
    >
      {/* File selection handeller */}
      {!props.isLoading && !data ? (
        <label className="w-full h-[119px] flex flex-col items-center px-4 py-7 bg-white text-gray-400 transition-all hover:text-gray-500 cursor-pointer ">
          <VscCloudUpload size={35} />
          <span className="mt-2 text-sm">Select an image</span>
          <input
            type="file"
            className="hidden"
            accept="image/*"
            onChange={(event) => handleUpload(event)}
          />
        </label>
      ) : null}

      {/* Loading spinner */}
      {props.isLoading && !data ? (
        <div className="w-full grid place-items-center h-[119px]">
          <CgSpinner size={40} className="animate-spin text-gray-400" />
        </div>
      ) : null}

      {/* File previewer */}
      {!props.isLoading && data ? (
        <div className="w-full flex px-4 py-7 h-[119px]">
          <div className="min-w-[63px]">
            <img
              src={data}
              alt="User avatar"
              className="w-[63px] h-[63px] rounded-full"
            />
          </div>
          <div className="grow overflow-hidden pl-2 pt-4">
            {/* <div className="inline-flex">
              <BiCheckDouble size={18} className="text-green-700" />
              <p className="text-xs text-gray-500">Image uploaded</p>
            </div> */}
            <button
              type="button"
              className="text-xs ml-[18px] py-[6px] px-2 transition-all rounded-md bg-gray-100 hover:bg-gray-200 text-gray-500"
              onClick={handleClear}
            >
              Choose Another
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};
