import { useCallback, useEffect, useState } from "react";
import { Card } from "components/card";
import { ITeam } from "types/team.types";
import { NetworkServices } from "network";
import { NotFound } from "components/404";
import { useParams } from "react-router-dom";
import { NetworkError } from "components/501";
import { Toastify } from "components/toastify";
import { PageHeader } from "components/page-header";
import { networkErrorHandeller } from "utils/helper";
import { TeamForm } from "components/form/team.form";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { FormLoader } from "components/ghostloader/form.ghostloader";

export const TeamEdit: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const [data, setData] = useState<ITeam | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [serverError, setServerError] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);

  /* Fetch data */
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await NetworkServices.Team.show(id || "");
      if (response && response.status === 200) {
        setData(response?.data?.data);
      }
      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        setServerError(true);
        networkErrorHandeller(error);
      }
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  /* Handle submit */
  const handleSubmit = async (data: any) => {
    try {
      setUpdate(true);
      const response = await NetworkServices.Team.update(id || "", data);
      if (response && response.status === 200) {
        Toastify.Success(response.data.message);
      }

      setUpdate(false);
    } catch (error: any) {
      if (error) {
        setUpdate(false);
        networkErrorHandeller(error);
      }
    }
  };

  return (
    <div>
      <PageHeader
        title="Edit team information."
        buttonIcon={<HiOutlineChevronLeft size={18} />}
        redirectPath="/team"
      />

      {isLoading && !serverError && !data ? (
        <Card className="lg:p-5">
          <FormLoader />
        </Card>
      ) : null}

      {!isLoading && !data && serverError ? <NetworkError /> : null}
      {!isLoading && !data && !serverError ? (
        <NotFound message="Team not found." />
      ) : null}

      {!isLoading && !serverError && data ? (
        <Card className="lg:p-5">
          <TeamForm
            formType="edit"
            data={data}
            loading={isUpdate}
            onSubmit={(data) => handleSubmit(data)}
          />
        </Card>
      ) : null}
    </div>
  );
};
