import Logo from "assets/logo.png";
import Four0Four from "assets/404.png";
import Five0One from "assets/501.png";
import NoContent from "assets/204.png";
import Avatar from "assets/avatar.png";
import ProfileBanner from "assets/profile-banner.png";

export const Images = {
  Logo,
  Four0Four,
  Five0One,
  NoContent,
  Avatar,
  ProfileBanner,
};
