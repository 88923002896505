import { useCallback, useEffect, useState } from "react";
import { NetworkServices } from "network";
import { useNavigate } from "react-router-dom";
import { Card } from "components/card";
import { Toastify } from "components/toastify";
import { LoginForm } from "components/form/login.form";
import {
  networkErrorHandeller,
  setToken,
  getToken,
  getPermissionFromToken,
} from "utils/helper";
import { Images } from "utils/images";

export const Login: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);

  /* Handle login */
  const handleLogin = async (data: any) => {
    try {
      setLoading(true);
      const response = await NetworkServices.Authentication.login(data);

      if (response && response.status === 200 && response.data.status) {
        Toastify.Success(response.data.message);

        /* Get permissions from token */
        const permissions = getPermissionFromToken(
          response.data.data.access_token
        );

        if (permissions && permissions.length) {
          await setToken(response.data.data.access_token);
          if (permissions[0] === "all") {
            navigate("/dashboard");
          } else {
            navigate(`/${permissions[0]}`);
          }
        }
      }

      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        networkErrorHandeller(error);
      }
    }
  };

  /* Check stored token */
  const isTokenStored = useCallback(async () => {
    const storedToken = await getToken();
    if (storedToken) {
      navigate("/dashboard");
    } else {
      navigate(`/`);
    }
  }, [navigate]);

  useEffect(() => {
    isTokenStored();
  }, [isTokenStored]);

  return (
    <div className="grid h-screen place-items-center p-3">
      <div className="mx-auto w-full sm:w-[500px]">
        <Card className="p-6">
          <div className="text-center mb-8">
            <img
              src={Images.Logo}
              alt="Logo"
              className="w-[180px] h-[80px] mx-auto mb-2"
            />

            <p className="text-2xl font-bold">Login</p>
            <p className="text-center text-sm text-muted">
              Login as administratior.
            </p>
          </div>
          <LoginForm
            loading={isLoading}
            onSubmit={(data) => handleLogin(data)}
          />
        </Card>
      </div>
    </div>
  );
};
