import React from "react";
import { Outlet } from "react-router-dom";
import { Navbar } from "components/navbar";
import { ProfileDropdown } from "components/profile-dropdown";
import { SidebarMenuItems } from "components/sidebar-menu-items";

export const DashbaordLayout: React.FC = (): JSX.Element => {
  return (
    <div className="w-full">
      {/* Navbar container in small screen */}
      <div className="fixed top-0 left-0 w-full px-3 pt-3 pb-2 z-50 lg:hidden bg-white">
        <Navbar />
      </div>

      {/* Sidebar container in large screen */}
      <div className="fixed top-0 left-0 bottom-0 w-[320px] hidden lg:block overflow-hidden p-4">
        <div className="w-full min-h-full max-h-full p-3 shadow-lg rounded-lg overflow-x-hidden overflow-y-auto hide-scrollbar bg-white">
          <div className="pb-2">
            <ProfileDropdown />
          </div>
          <SidebarMenuItems />
        </div>
      </div>

      {/* Outlet container */}
      <div className="lg:grow p-3 lg:p-4 lg:pl-[320px] mt-[73px] lg:mt-0">
        <Outlet />
      </div>
    </div>
  );
};
