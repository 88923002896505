import React, { useCallback, useState, useEffect } from "react";
import { Card } from "components/card";
import { PageHeader } from "components/page-header";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { PermissionForm } from "components/form/permission.form";
import { IRPermissionCreateUpdate, IRRole } from "types/permission.types";
import { networkErrorHandeller } from "utils/helper";
import { NetworkServices } from "network";
import { FormLoader } from "components/ghostloader/form.ghostloader";
import { Toastify } from "components/toastify";

export const PermissionCreate: React.FC = (): JSX.Element => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isCreated, setCreated] = useState<boolean>(false);
  const [roles, setRoles] = useState<IRRole[] | []>([]);

  /** fetch role list data */
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await NetworkServices.Permission.roles();
      if (response && response.status === 200) {
        setRoles(response?.data?.data);
      }
      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        networkErrorHandeller(error);
      }
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  /** Handle submission */
  const handleSubmit = async (value: IRPermissionCreateUpdate) => {
    try {
      setCreated(true);

      const response = await NetworkServices.Permission.store(value);
      if (response && response.status === 201) {
        Toastify.Success(response.data.message);
      }
      setCreated(false);
    } catch (error: any) {
      setCreated(false);
      networkErrorHandeller(error);
    }
  };

  return (
    <div>
      <PageHeader
        title="Create permission"
        buttonIcon={<HiOutlineChevronLeft size={18} />}
        redirectPath="/permission"
      />

      <Card className="lg:p-6 !overflow-visible">
        {/* Preloader preview */}
        {isLoading ? <FormLoader /> : null}

        {/* Data form preview */}
        {!isLoading ? (
          <PermissionForm
            data={null}
            loading={isCreated}
            roleList={roles}
            onSubmit={handleSubmit}
          />
        ) : null}
      </Card>
    </div>
  );
};
