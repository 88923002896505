import { privateRequest } from "config/axios.config";
import { PaginationParamsTypes } from "types/pagination.types";
import { IRPermissionCreateUpdate } from "types/permission.types";

/* List of resources */
export const index = async (reqParams: PaginationParamsTypes) => {
  return await privateRequest.get(`api/admin/permission`, {
    params: { ...reqParams },
  });
};

/* List of resources */
export const all = async () => {
  return await privateRequest.get(`api/admin/permission/all-permission`);
};

/* List of resources */
export const roles = async () => {
  return await privateRequest.get(`api/admin/permission/roles`);
};

/* Store resource */
export const store = async (data: IRPermissionCreateUpdate) => {
  return await privateRequest.post(`api/admin/permission`, data);
};

/* Show specific resource */
export const show = async (id: string) => {
  return await privateRequest.get(`api/admin/permission/${id}`);
};

/* Update specific resource */
export const update = async (id: string, data: IRPermissionCreateUpdate) => {
  return await privateRequest.put(`api/admin/permission/${id}`, data);
};

/* Destroy specific resource */
export const destroy = async (id: string) => {
  return await privateRequest.delete(`api/admin/permission/${id}`);
};
