import { useForm } from "react-hook-form";
import { PrimaryButton } from "components/button";
import { TextInput } from "components/input";
import { ITeam } from "types/team.types";
import { FileUploader } from "components/file-uploader";
import { isValidURL } from "utils/helper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect } from "react";

type PropsTypes = {
  loading: boolean;
  formType: "store" | "edit";
  data: ITeam | null;
  onSubmit: (data: any) => void;
};

export const TeamForm: React.FC<PropsTypes> = (
  props: PropsTypes
): JSX.Element => {
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    watch,
    formState: { errors },
  } = useForm();
  const watchDescription = watch("description");

  useEffect(() => {
    if (props.data) {
      setValue("description", props.data.description);
    }
  }, [props, setValue]);

  /* Handle set value */
  const handleSetValue = ({ key, value }: { key: string; value: string }) => {
    setValue(`${key}`, value);
    clearErrors(key);
  };

  /* Handle form submit */
  const onSubmit = (data: any) => {
    if (!data.description) {
      return setError("description", {
        type: "custom",
        message: "Description is required.",
      });
    }

    if (props.formType === "store" && !data.profile_image) {
      return setError("profile_image", {
        type: "custom",
        message: "Profile image is required.",
      });
    }

    if (props.formType === "store") {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("facebook", data.facebook);
      formData.append("linkedin", data.linkedin);
      formData.append("twitter", data.twitter);
      formData.append("profile_image", data.profile_image);

      props.onSubmit(formData);
    }

    if (props.formType === "edit") {
      props.onSubmit({ ...data });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 lg:gap-6">
        {/* Name input control */}
        <div className="mb-6 lg:mb-2">
          <TextInput
            label="Name"
            name="name"
            type="text"
            placeholder="Enter name"
            control={control}
            error={errors.name && errors.name.message}
            defaultvalue={props.data?.name || ""}
            rules={{ required: "Name is required" }}
          />
        </div>

        {/* Description input control */}
        <div className="mb-6">
          {errors.description && errors.description.message ? (
            <p className="text-sm mb-1 text-danger">
              Member description is required.
            </p>
          ) : (
            <p className="text-sm mb-1 text-gray-500">Member description</p>
          )}
          <ReactQuill
            theme="snow"
            placeholder="Write member description..."
            value={watchDescription || ""}
            onChange={(data) =>
              handleSetValue({ key: "description", value: data })
            }
          />
        </div>

        {/* Facebook input control */}
        <div className="mb-6 lg:mb-2">
          <TextInput
            label="Facebook"
            name="facebook"
            type="text"
            placeholder="https://www.facebook.com/username"
            control={control}
            error={errors.Facebook && errors.Facebook.message}
            defaultvalue={props.data?.facebook || ""}
            rules={{
              pattern: {
                value: isValidURL(),
                message: "Invalid address.",
              },
            }}
          />
        </div>

        {/* Linkedin input control */}
        <div className="mb-6 lg:mb-2">
          <TextInput
            label="Linkedin"
            name="linkedin"
            type="text"
            placeholder="https://www.linkedin.com/username"
            control={control}
            error={errors.linkedin && errors.linkedin.message}
            defaultvalue={props.data?.linkedin || ""}
            rules={{
              pattern: {
                value: isValidURL(),
                message: "Invalid address.",
              },
            }}
          />
        </div>

        {/* Twitter input control */}
        <div>
          <TextInput
            label="Twitter"
            name="twitter"
            type="text"
            placeholder="https://www.twitter.com/username"
            control={control}
            error={errors.twitter && errors.twitter.message}
            defaultvalue={props.data?.twitter || ""}
            rules={{
              pattern: {
                value: isValidURL(),
                message: "Invalid address.",
              },
            }}
          />
        </div>
      </div>

      {/* Profile image input */}
      {props.formType === "store" ? (
        <div className="my-6">
          <div className="w-full sm:w-72 mx-auto">
            <p className="text-sm mb-2 text-muted">Banner size (450X450)</p>
            <FileUploader
              isLoading={false}
              defaultValue={props.data?.profile_image || null}
              error={errors.profile_image && errors.profile_image.message}
              onUploded={(data) =>
                handleSetValue({ key: "profile_image", value: data })
              }
            />
          </div>
        </div>
      ) : null}

      {/* Submit button */}
      <div className={`text-center ${props.formType === "edit" ? "pt-6" : ""}`}>
        <PrimaryButton type="submit" disabled={props.loading}>
          {props.loading ? "Loading..." : "Submit"}
        </PrimaryButton>
      </div>
    </form>
  );
};
