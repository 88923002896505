import { useCallback, useState, useEffect } from "react";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { PageHeader } from "components/page-header";
import { AdminForm } from "components/form/admin.form";
import { Card } from "components/card";
import { networkErrorHandeller } from "utils/helper";
import { NetworkServices } from "network";
import { Toastify } from "components/toastify";
import { IROption } from "types/permission.types";
import { FormLoader } from "components/ghostloader/form.ghostloader";

export const AdminStore: React.FC = (): JSX.Element => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isCreated, setCreated] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<IROption[] | []>([]);

  /** fetch role list data */
  const fetchData = useCallback(async () => {
    try {
      const response = await NetworkServices.Permission.all();
      if (response && response.status === 200) {
        const masterData = response?.data?.data;
        const dataArr: IROption[] = [];

        for (let i = 0; i < masterData.length; i++) {
          const element = masterData[i];
          dataArr.push({
            label: element.name,
            value: element.id,
          });
        }
        setPermissions(dataArr);
      }
      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        networkErrorHandeller(error);
      }
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  /* Handle form submission */
  const handleSubmit = async (data: any) => {
    try {
      setCreated(true);
      const response = await NetworkServices.Admin.store(data);
      if (response && response.status === 201) {
        Toastify.Success(response.data.message);
      }

      setCreated(false);
    } catch (error: any) {
      if (error) {
        setCreated(false);
        networkErrorHandeller(error);
      }
    }
  };

  return (
    <div>
      <PageHeader
        title="Create admin"
        buttonIcon={<HiOutlineChevronLeft size={18} />}
        redirectPath="/admin"
      />

      <Card className="lg:p-6 !overflow-visible">
        {/* Preloader preview */}
        {isLoading ? <FormLoader /> : null}

        {/* Data form preview */}
        {!isLoading ? (
          <AdminForm
            formType="store"
            data={null}
            loading={isCreated}
            permissions={permissions}
            onSubmit={(data) => handleSubmit(data)}
          />
        ) : null}
      </Card>
    </div>
  );
};
