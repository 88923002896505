import { PrimaryButton } from "components/button";
import { useForm, SubmitHandler } from "react-hook-form";
import { isValidEmail, isValidPhone } from "utils/helper";
import { TextInput, PasswordInput, SingleSelect } from "components/input";
import { IAdminShow, IAdminStore } from "types/admin.types";
import { IROption } from "types/permission.types";

type PropsTypes = {
  loading: boolean;
  formType: "store" | "edit";
  data: IAdminShow | null;
  permissions: IROption[] | [];
  onSubmit: (data: any) => void;
};

export const AdminForm: React.FC<PropsTypes> = (
  props: PropsTypes
): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IAdminStore>();

  /* Handle form submit */
  const onSubmit: SubmitHandler<IAdminStore> = (data) => {
    const formData = {
      ...data,
      permission: data.permission.value,
    };
    props.onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-6">
        {/* Name input control */}
        <div className="mb-6 lg:mb-2">
          <TextInput
            label="Name"
            name="name"
            type="text"
            placeholder="Enter admin name"
            control={control}
            error={errors.name && errors.name.message}
            defaultvalue={props.data?.name || ""}
            rules={{ required: "Name is required" }}
          />
        </div>

        {/* Email input control */}
        <div className="mb-6 lg:mb-2">
          <TextInput
            label="Email address"
            name="email"
            type="text"
            placeholder="Enter email address"
            control={control}
            error={errors.email && errors.email.message}
            defaultvalue={props.data?.email || ""}
            rules={{
              required: "Email is required.",
              pattern: {
                value: isValidEmail(),
                message: "Invalid email address.",
              },
            }}
          />
        </div>

        {/* Phone input control */}
        <div className="mb-6 lg:mb-2">
          <TextInput
            label="Phone number"
            name="phone"
            type="text"
            placeholder="01X-XXXX-XXXX"
            control={control}
            error={errors.phone && errors.phone.message}
            defaultvalue={props.data?.phone || ""}
            rules={{
              required: "Phone number is required.",
              pattern: {
                value: isValidPhone(),
                message: "Invalid phone number.",
              },
            }}
          />
        </div>

        {/* Role selection control */}
        <div className="mb-6 lg:mb-2">
          <SingleSelect
            label="Permission"
            name="permission"
            control={control}
            error={errors.permission && errors.permission.message}
            options={props.permissions}
            isClearable={true}
            placeholder="Select permission"
            rules={{ required: "Permission is required" }}
            defaultvalue={
              props.data
                ? {
                    label: props.data.permission.name,
                    value: props.data.permission.id,
                  }
                : null
            }
          />
        </div>

        {/* Password input control */}
        {props.formType === "store" ? (
          <>
            <div className="mb-6">
              <PasswordInput
                label="Password"
                name="password"
                type="text"
                placeholder="********"
                control={control}
                error={errors.password && errors.password.message}
                defaultvalue={""}
                rules={{ required: "Password is required" }}
              />
            </div>
          </>
        ) : null}
      </div>

      {/* Submit button */}
      <div className="text-right">
        <PrimaryButton type="submit" disabled={props.loading}>
          {props.loading ? "Loading..." : "Submit"}
        </PrimaryButton>
      </div>
    </form>
  );
};
