import { privateRequest } from "config/axios.config";
import { IChangePassword } from "types/profile.types";

/* List of resources */
export const me = async () => {
  return await privateRequest.get(`api/admin/profile`);
};

/* Store resource */
export const changePassword = async (data: IChangePassword) => {
  return await privateRequest.post(`api/admin/profile/change-password`, data);
};
