import { PrimaryButton } from "components/button";
import { useNavigate } from "react-router-dom";
import { removeToken } from "utils/helper";
import { Images } from "utils/images";

type PropsTypes = {
  message: string;
};

export const NotFound: React.FC<PropsTypes> = (
  props: PropsTypes
): JSX.Element => {
  const navigate = useNavigate();

  /** Handle logout and redirect to login */
  const handleGoBack = () => {
    removeToken();
    navigate("/");
    return;
  };

  return (
    <div className="p-5 2xl:p-10 w-full rounded-xl overflow-hidden sm:w-[500px] text-center bg-white">
      <img
        src={Images.Four0Four}
        alt="Page not found"
        className="mx-auto w-[250px] h-[250px] sm:w-[350px] sm:h-[350px]"
      />
      <p className="text-base font-medium text-secondary mt-4">
        {props.message}
      </p>

      <div className="text-center my-4">
        <PrimaryButton type="button" className="!px-7" onClick={handleGoBack}>
          Go Back
        </PrimaryButton>
      </div>
    </div>
  );
};
