import { privateRequest } from "config/axios.config";
import { ITeamCreate, ITeamUpdate } from "types/team.types";
import { PaginationParamsTypes } from "types/pagination.types";

/* List of resources */
export const index = async (reqParams: PaginationParamsTypes) => {
  return await privateRequest.get(`api/admin/team`, {
    params: { ...reqParams },
  });
};

/* Store resource */
export const store = async (data: ITeamCreate) => {
  return await privateRequest.post(`api/admin/team`, data);
};

/* Show specific resource */
export const show = async (id: string) => {
  return await privateRequest.get(`api/admin/team/${id}`);
};

/* Update specific resource */
export const update = async (id: string, data: ITeamUpdate) => {
  return await privateRequest.put(`api/admin/team/${id}`, data);
};

/* Destroy specific resource */
export const destroy = async (id: string) => {
  return await privateRequest.delete(`api/admin/team/${id}`);
};
