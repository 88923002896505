import { useCallback, useEffect, useState } from "react";
import { Card } from "components/card";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { ITeam } from "types/team.types";
import { NetworkServices } from "network";
import { DataTable } from "components/table";
import { Toastify } from "components/toastify";
import { PageHeader } from "components/page-header";
import { networkErrorHandeller } from "utils/helper";
import { WhiteCircleButton } from "components/button";
import { IRDataColumns } from "types/datatable.types";
import { HiPlus, HiOutlineTrash } from "react-icons/hi";
import { ConfirmationModal } from "components/modal/confirm.modal";
import { ImFacebook2, ImLinkedin, ImTwitter } from "react-icons/im";
import HTMLParser from "html-react-parser";

export const TeamIndex: React.FC = (): JSX.Element => {
  const [data, setData] = useState<ITeam[] | []>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [willDelete, setWillDelete] = useState<any>({
    id: null,
    show: false,
    loading: false,
  });

  /* Fetch data */
  const fetchData = useCallback(async (page: number) => {
    try {
      setLoading(true);
      const response = await NetworkServices.Team.index({
        page,
      });
      if (response && response.status === 200) {
        setData(response?.data?.data?.data);
        setTotalRows(response?.data?.data?.total);
      }
      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        networkErrorHandeller(error);
      }
    }
  }, []);

  useEffect(() => {
    fetchData(1);
  }, [fetchData]);

  /* handle paginate page change */
  const handlePageChange = (page: number) => fetchData(page);

  /* handle delete */
  const handleDelete = async () => {
    try {
      setWillDelete({ ...willDelete, loading: true });
      const response = await NetworkServices.Team.destroy(willDelete.id || "");

      if (response && response.status === 200) {
        fetchData(1);
        Toastify.Success(response.data.message);
      }

      setWillDelete({ id: null, show: false, loading: false });
    } catch (error: any) {
      if (error) {
        setWillDelete({ id: null, show: false, loading: false });
        networkErrorHandeller(error);
      }
    }
  };

  /* data columns */
  const columns: IRDataColumns[] = [
    {
      name: "",
      minWidth: "100px",
      maxWidth: "100px",
      cell: (row) => (
        <img
          src={row.profile_image}
          alt="Team avatar"
          className="min-w-[80px] min-h-[80px] rounded-md"
        />
      ),
    },
    {
      name: "Details",
      minWidth: "300px",
      cell: (row) => (
        <div>
          <div className="mb-1">
            <p className="font-bold text-sm">{row.name}</p>
            {HTMLParser(row.description)}
          </div>

          <SocialProfile
            facebook={row.facebook || null}
            linkedin={row.linkedin || null}
            twitter={row.twitter || null}
          />
        </div>
      ),
    },
    {
      name: "Action",
      maxWidth: "100px",
      cell: (row) => (
        <div className="flex gap-1">
          <Link to={`/team/edit/${row.id}`}>
            <WhiteCircleButton type="button">
              <MdEdit size={18} />
            </WhiteCircleButton>
          </Link>
          <WhiteCircleButton
            type="button"
            onClick={() =>
              setWillDelete({ id: row.id, show: true, loading: false })
            }
          >
            <HiOutlineTrash size={18} />
          </WhiteCircleButton>
        </div>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        title="Team list"
        redirectPath="/team/create"
        buttonIcon={<HiPlus size={18} />}
      />

      <Card>
        <DataTable
          data={data}
          columns={columns}
          loading={isLoading}
          pagination={true}
          paginationServer={true}
          totalRows={totalRows}
          handlePageChange={handlePageChange}
          noDataMessage="No data found."
        />
      </Card>

      {/* Delete confirmation modal */}
      <ConfirmationModal
        show={willDelete.show}
        title="Are you sure you want to delete?"
        loading={willDelete.loading}
        onHide={() => setWillDelete({ id: null, loading: false, show: false })}
        onConfirm={handleDelete}
      />
    </div>
  );
};

/** Social profiles */
type SocialProfileTypes = {
  facebook?: string;
  linkedin?: string;
  twitter?: string;
};
const SocialProfile: React.FC<SocialProfileTypes> = (
  props: SocialProfileTypes
): JSX.Element => {
  const className = "text-gray-300 transition-all hover:text-secondary";
  return (
    <div className="flex gap-4 pt-4">
      {props.facebook ? (
        <a
          href={props.facebook}
          target="_blank"
          rel="noreferrer"
          className={className}
        >
          <ImFacebook2 size={20} />
        </a>
      ) : null}

      {props.linkedin ? (
        <a
          href={props.linkedin}
          target="_blank"
          rel="noreferrer"
          className={className}
        >
          <ImLinkedin size={20} />
        </a>
      ) : null}

      {props.twitter ? (
        <a
          href={props.twitter}
          target="_blank"
          rel="noreferrer"
          className={className}
        >
          <ImTwitter size={20} />
        </a>
      ) : null}
    </div>
  );
};
