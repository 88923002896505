import { WhiteCircleButton } from "components/button";
import { Card } from "components/card";
import { Link } from "react-router-dom";

type PropsTypes = {
  title: string;
  buttonIcon: React.ReactNode;
  redirectPath: string;
};

export const PageHeader: React.FC<PropsTypes> = (
  props: PropsTypes
): JSX.Element => {
  return (
    <Card className="mb-4">
      <div className="flex justify-between">
        <p className="font-medium text-base mt-[5px] text-muted">
          {props.title}
        </p>

        {props.buttonIcon ? (
          <Link to={props.redirectPath}>
            <WhiteCircleButton type="button">
              {props.buttonIcon}
            </WhiteCircleButton>
          </Link>
        ) : null}
      </div>
    </Card>
  );
};
