import { useCallback, useEffect, useState } from "react";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { PageHeader } from "components/page-header";
import { CategoryForm } from "components/form/category.form";
import { Card } from "components/card";
import { networkErrorHandeller } from "utils/helper";
import { NetworkServices } from "network";
import { Toastify } from "components/toastify";
import { ICategory } from "types/category.types";
import { useParams } from "react-router-dom";
import { FormLoader } from "components/ghostloader/form.ghostloader";
import { NetworkError } from "components/501";
import { NotFound } from "components/404";

export const CategoryEdit: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const [data, setData] = useState<ICategory | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isUpdating, setUpdating] = useState<boolean>(false);
  const [serverError, setServerError] = useState<boolean>(false);

  /* get specific resource data */
  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await NetworkServices.Category.show(id || "");
      if (response && response.status === 200) {
        setData(response?.data?.data);
      }
      setLoading(false);
    } catch (error) {
      if (error) {
        setLoading(false);
        setServerError(true);
        networkErrorHandeller(error);
      }
    }
  }, [id]);

  useEffect(() => {
    getData();
  }, [getData]);

  /* Handle form submission */
  const handleSubmit = async (data: any) => {
    try {
      setUpdating(true);
      const response = await NetworkServices.Category.update(id || "", data);
      if (response && response.status === 200) {
        Toastify.Success(response.data.message);
      }

      setUpdating(false);
    } catch (error: any) {
      if (error) {
        setUpdating(false);
        networkErrorHandeller(error);
      }
    }
  };

  return (
    <div>
      <PageHeader
        title="Create edit"
        buttonIcon={<HiOutlineChevronLeft size={18} />}
        redirectPath="/category"
      />

      {isLoading && !serverError && !data ? (
        <Card className="lg:p-5">
          <FormLoader />
        </Card>
      ) : null}

      {!isLoading && !data && serverError ? <NetworkError /> : null}
      {!isLoading && !data && !serverError ? (
        <NotFound message="Category not found." />
      ) : null}

      {!isLoading && !serverError && data ? (
        <Card className="lg:p-6">
          <CategoryForm
            data={data}
            loading={isUpdating}
            onSubmit={(data) => handleSubmit(data)}
          />
        </Card>
      ) : null}
    </div>
  );
};
