import { useCallback, useEffect, useState } from "react";
import { Card } from "components/card";
import { DataTable } from "components/table";
import { IRDataColumns } from "types/datatable.types";
import { NetworkServices } from "network";
import { networkErrorHandeller } from "utils/helper";
import { PageHeader } from "components/page-header";
import { ISubscription } from "types/subscription.types";

export const SubscriptionIndex: React.FC = (): JSX.Element => {
  const [data, setData] = useState<ISubscription[] | []>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [totalRows, setTotalRows] = useState<number>(0);

  /* Fetch data */
  const fetchData = useCallback(async (page: number) => {
    try {
      setLoading(true);
      const response = await NetworkServices.Subscription.index({
        page,
      });
      if (response && response.status === 200) {
        setData(response?.data?.data?.data);
        setTotalRows(response?.data?.data?.total);
      }
      setLoading(false);
    } catch (error: any) {
      if (error) {
        setLoading(false);
        networkErrorHandeller(error);
      }
    }
  }, []);

  useEffect(() => {
    fetchData(1);
  }, [fetchData]);

  /* handle paginate page change */
  const handlePageChange = (page: number) => fetchData(page);

  /* data columns */
  const columns: IRDataColumns[] = [
    {
      name: "ID",
      maxWidth: "50px",
      selector: (row) => row.id,
    },
    {
      name: "E-mail",
      selector: (row) => row.email,
    },
  ];

  return (
    <div>
      <PageHeader
        title="Subscribed email list."
        redirectPath=""
        buttonIcon={null}
      />

      <Card>
        <DataTable
          data={data}
          columns={columns}
          loading={isLoading}
          pagination={true}
          paginationServer={true}
          totalRows={totalRows}
          handlePageChange={handlePageChange}
          noDataMessage="No data found."
        />
      </Card>
    </div>
  );
};
