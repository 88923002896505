import { privateRequest } from "config/axios.config";
import { IBlogCreate, IBlogUpdate } from "types/blog.types";
import { PaginationParamsTypes } from "types/pagination.types";

/* List of resources */
export const index = async (reqParams: PaginationParamsTypes) => {
  return await privateRequest.get(`api/admin/blog`, {
    params: { ...reqParams },
  });
};

/* Store resource */
export const store = async (data: IBlogCreate) => {
  return await privateRequest.post(`api/admin/blog`, data);
};

/* Show specific resource */
export const show = async (id: string) => {
  return await privateRequest.get(`api/admin/blog/${id}`);
};

/* Update specific resource */
export const update = async (id: string, data: IBlogUpdate) => {
  return await privateRequest.put(`api/admin/blog/${id}`, data);
};

/* Destroy specific resource */
export const destroy = async (id: string) => {
  return await privateRequest.delete(`api/admin/blog/${id}`);
};
