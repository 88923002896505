import { useState } from "react";
import { IoIosMenu } from "react-icons/io";
import { Drawer } from "components/drawer";
import { WhiteCircleButton } from "components/button";
import { ProfileDropdown } from "components/profile-dropdown";
import { SidebarMenuItems } from "components/sidebar-menu-items";

export const Navbar: React.FC = (): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);

  /* Handle drawer */
  const handleDrawer = () => setOpen(!open);

  return (
    <>
      <div className="flex justify-between">
        <ProfileDropdown />
        <div>
          <WhiteCircleButton type="button" onClick={handleDrawer}>
            <IoIosMenu size={25} />
          </WhiteCircleButton>
        </div>
      </div>

      {/* Sidebar drawer */}
      <Drawer show={open} onClick={handleDrawer}>
        <SidebarMenuItems />
      </Drawer>
    </>
  );
};
